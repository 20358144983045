@use "themes/color";

.header {
  position: relative;
  background-color: #2b2b2b;
  height: 182px;
  padding-top: 32px;
  padding-bottom: 80px;
  padding-inline: 50px;
  width: 100%;
  gap: 8px;

  h2,
  span {
    margin: 0;
    color: color.$NEUTRAL-10;
    max-width: 1300px;
  }

  h2 {
    font-size: calc(24em / 14);
    @media (max-width: 768px) {
      color: color.$NEUTRAL-10;
      text-align: center;
      font-size: 16px;
      font-weight: 700;
      line-height: 24px;
    }
  }

  span {
    font-size: calc(16em / 14);
    @media (max-width: 768px) {
      color: color.$NEUTRAL-10;
      text-align: center;
      font-size: 12px;
      font-weight: 400;
      line-height: 14px;
    }
  }

  @media (max-width: 576px) {
    row-gap: 8px;
    padding-inline: 25px;
  }
  .backButton {
    position: relative;
    left: -620px;
    top: -90px;
    border: none;
    background-color: color.$PRIMARY;
    padding: 12px 16px;
    display: flex;
    align-items: center;
    border-radius: 8px;
    gap: 4px;
    &:hover {
      background-color: color.$PRIMARY-HOVER;
    }
    &:active {
      background-color: color.$PRIMARY-PRESSED;
    }
    > .backIcon {
      width: 16px;
      height: 16px;
      flex-shrink: 0;
      transform: rotate(180deg);
    }
    > span {
      color: color.$NEUTRAL-10;
      font-size: 1rem;
      font-weight: 700;
    }
    @media (max-width: 1024px) {
      position: absolute;
      padding: 8px 12px;
      top: 16px;
      left: 32px;
      font-size: 14px;
      > .backIcon {
        width: 12px;
        height: 12px;
      }
      > span {
        color: color.$NEUTRAL-10;
        font-size: 14px;
        font-weight: 700;
      }
    }
  }
  @media (max-width: 1024px) {
    height: 200px;
    align-items: center;
    justify-content: center;
  }
}
