@import "../../../themes/color";

@mixin flexColumn($gap) {
  display: flex;
  flex-direction: column;
  row-gap: $gap;
}
.wrapperErrorGetData {
  background-color: $NEUTRAL-10;
  padding: 24px;
  display: flex;
  align-items: center;
  justify-content: center;
  @include flexColumn(1rem);
  height: 90vh;
  > .containError {
    @include flexColumn(10px);
    align-items: center;
    > img {
      width: 80px;
      height: 80px;
    }
    h1 {
      color: $NEUTRAL-90;
      font-size: 36px;
      font-weight: 700;
    }
    > p {
      color: $NEUTRAL-60;
      text-align: center;
      font-size: 24px;
      font-weight: 400;
    }
  }
  .btnModalError {
    padding: 10px 12px;
    border-radius: 8px;
    display: flex;
    justify-content: center;
    cursor: pointer;
    background-color: $PRIMARY;
    outline: none;
    border: none;
    gap: 8px;
    align-items: center;
    &:hover {
      background-color: $PRIMARY_HOVER;
      span {
        transition: 0.4s;
        display: flex;
        transform: rotate(320deg);
      }
    }
    strong {
      font-size: 18px;
      font-weight: 700;
      color: $NEUTRAL-10;
    }
    span {
      transition: 0.4s;
      display: flex;
      &:hover {
        transform: rotate(320deg);
      }
      &:active {
        transform: rotate(360deg);
      }
    }
  }
}
@media (max-width: 768px) {
  .wrapperErrorGetData {
    > .containError {
      > img {
        width: 60px;
        height: 60px;
      }
      > h1 {
        font-size: 1rem;
      }
      > p {
        font-size: 14px;
      }
    }
    .btnModalError {
      padding: 8px 12px;
      strong {
        font-size: 14px;
      }
    }
  }
}
