@use "themes/color";
@import "themes/global";
.wrapperDetail {
  width: 100%;
  .wrapperTabs {
    @media (max-width: 460px) {
      width: 100% !important;
      border: none !important;
    }
    @media (max-width: 1024px) {
      width: calc(90% - 1rem);
      // border: 1px solid color.$NEUTRAL
      border: 1px solid color.$NEUTRAL-40;
      &:last-child {
        border-right: 1px solid color.$NEUTRAL-40 !important;
      }
      &:first-child {
        border-left: 1px solid color.$NEUTRAL-40 !important;
      }
    }
    height: fit-content;
    display: flex;
    background-color: color.$NEUTRAL-10;
    width: 100%;
    .tab {
      width: 100%;
      padding: 1rem;
      display: flex;
      justify-content: center;
      cursor: pointer;
      border: 1px solid color.$NEUTRAL-40;
      &:last-child {
        border-right-color: transparent;
      }
      &:first-child {
        border-left-color: transparent;
      }
      &:hover {
        background-color: color.$PRIMARY-SURFACE;
      }
      span {
        font-weight: 700;
        color: color.$NEUTRAL-100;
      }
      &:nth-child(1) {
        border-right-color: transparent;
      }
      &:nth-child(2) {
        border-right-color: transparent;
      }
    }
    .tab[is-active="true"] {
      background-color: color.$PRIMARY;
      border-color: color.$PRIMARY;
      span {
        color: color.$NEUTRAL-10;
      }
    }
  }
  .mainContent {
    width: 100%;
    padding: 60px;
    @include flexCol(24px);
    align-items: center;
    justify-content: center;
    background-color: color.$NEUTRAL-10;
    .content {
      @include flex(1rem);
      max-width: 1176px;
      width: 100%;
      justify-content: flex-start;
      text-align: start;
      padding: 0;
      margin: 0;
      .img {
        img {
          width: 490px;
          height: 257px;
          flex-shrink: 0;
          object-fit: fill;
          border-radius: 4px;
        }
      }
      .paragraph {
        justify-content: center;
        @include flexCol(1rem);
        h3 {
          font-size: 24px;
        }
        p {
          font-size: 16px;
          line-height: 24px;
          color: color.$NEUTRAL-90;
        }
      }
    }
    .iframeYoutube {
      flex-shrink: 0;
      object-fit: contain;
      margin: 0;
      display: flex;
      justify-content: center;
      > iframe {
        border-radius: 8px;
      }
    }
    .wrapperSpec {
      @include flexCol(1rem);
      // align-items: center;
      max-width: 1175px;
      height: auto;
      width: 100%;
      .accordionSpec {
        padding: 1rem;
        box-shadow: 0px 0px 20px 0px rgba(0, 0, 0, 0.2);
        border-radius: 8px;
        .header {
          @include flex(1rem);
          > img {
            width: 118px;
            height: 88px;
            border-radius: 8px;
          }
          .wrapperHead {
            width: 100%;
            @include flexBetween();
            align-items: center;
            .rowHead {
              @include flexCol(8px);
              .headAndIcon {
                @include flex(8px);
                align-items: center;
                > strong {
                  color: color.$PRIMARY;
                  font-size: 24px;
                  font-weight: 700;
                }
              }
              > p {
                color: color.$NEUTRAL-90;
                font-size: 14px;
              }
            }
            .iconAccordion {
              cursor: pointer;
              flex-shrink: 0;
              color: color.$NEUTRAL-100;
            }
          }
        }
        .divider {
          width: 100%;
          height: 1px;
          background-color: color.$NEUTRAL-40;
          margin-block: 1rem;
        }
        .wrapperDescription {
          @include flex(0);
          opacity: 0;
          transition: 0.5s;
          height: 0;
          overflow: hidden;
          background-color: color.$NEUTRAL-10;
          border-radius: 8px;
          .desc {
            width: 100%;
            @include flexCol(0);
            color: color.$NEUTRAL-100;

            // h4 {
            //   // max-width: 364px;
            //   padding: 8px;
            //   color: color.$PRIMARY;
            //   font-size: 1rem;
            //   background-color: color.$NEUTRAL-10;
            //   width: 100%;
            // }
            // ul li {
            //   padding: 12px 8px;
            //   list-style-type: none;
            //   font-size: 14px;
            //   font-weight: 500;
            // }
            // &:nth-child(2) {
            //   ul li {
            //     font-weight: 400;
            //   }
            // }
            .headDesc {
              display: flex;
              width: 100%;
              width: 100%;
              h4 {
                padding-block: 4px 12px;
                width: 50%;
                color: color.$PRIMARY;
                font-size: 16px;
                line-height: 20px;
                font-weight: 700;
              }
            }
            .decLabelAndValue {
              .wrapList {
                color: color.$NEUTRAL-100;
                display: flex;
                width: 100%;
                align-items: flex-start;
                gap: 8px;
                > li {
                  font-size: 14px;
                  padding: 4px 0 8px 0;
                  list-style: none;
                  width: 50%;
                  line-height: 20px;
                  &:first-child {
                    font-weight: 500;
                  }
                }
              }
            }
          }
        }
        .wrapperDescription[is-open-accordion="true"] {
          height: fit-content;
          opacity: 1;
        }
      }
    }
  }
}
.viewerGrid {
  width: 100%;
  max-height: 772px;
  border-radius: 8px 8px 0 0;
  display: grid;
  grid-template-columns: 200px 1fr 300px;
  column-gap: 1px;
  background-color: color.$NEUTRAL-40;
}

.viewerContent,
.viewerSimulation {
  background-color: color.$NEUTRAL-10;
  .calculateWrapper {
    @include flexCol(12px);
    div:nth-child(1) {
      @include flexCol(8px);
      h5 {
        color: color.$NEUTRAL-100;
        font-size: 14px;
        font-weight: 700;
      }
      .wrapPercentAndPrice {
        @include flex(4px);
        .dropdownPercentage {
          position: relative;
          // flex: 0.8;
          width: fit-content;
          border: 1px solid color.$NEUTRAL-100;
          padding: 12px 1rem;
          border-radius: 8px;
          @include flex(8px);
          align-items: center;
          color: color.$NEUTRAL-100;
          > span {
            font-size: 14px;
          }
          > .chevron {
            width: 9.313px;
            height: 9.488px;
            flex-shrink: 0;
            color: color.$NEUTRAL-80;
            cursor: pointer;
            transition: 0.3s;
          }
          > .chevron[is-open="true"] {
            transform: rotate(180deg);
          }
          > .divider {
            width: 1.2px;
            height: 20px;
            background-color: color.$NEUTRAL-90;
            flex-shrink: 0;
          }
          .wrapOpenDrp {
            z-index: 99;
            position: absolute;
            width: 100%;
            height: fit-content;
            border-bottom-left-radius: 8px;
            border-bottom-right-radius: 8px;
            box-shadow: 0 1px 5px rgba(0, 0, 0, 0.388);
            background-color: color.$NEUTRAL-10;
            top: calc(100% + 0.2rem);
            left: 0;

            > ul li {
              font-size: 14px;
              text-align: center;
              list-style-type: none;
              padding: 6px 8px;
              cursor: pointer;
              &:hover {
                background-color: color.$PRIMARY-SURFACE;
              }
              &:not(:last-child) {
                border-bottom: 1px solid color.$NEUTRAL-50;
              }
            }
            > ul li[is-active="true"] {
              background-color: color.$PRIMARY;
              color: color.$NEUTRAL-10;
              &:last-child {
                border-bottom-left-radius: 8px;
                border-bottom-right-radius: 8px;
              }
            }
          }
        }
        .inputPrice {
          flex: 1.5;
          border: 1px solid color.$NEUTRAL-100;
          padding: 12px 1rem;
          border-radius: 8px;
          @include flex(4px);
          align-items: center;
          color: color.$NEUTRAL-100;
          > span {
            font-size: 14px;
          }
          > input[type="text"] {
            width: 100%;
            outline: none;
            border: none;
            &::placeholder {
              color: color.$NEUTRAL-100;
            }
          }
        }
      }
    }
    .dropdownTenor {
      position: relative;
      width: 100%;
      padding: 12px 1rem;
      height: 40px;
      border-radius: 8px;
      border: 1px solid color.$NEUTRAL-100;
      @include flexBetween();
      align-items: center;
      cursor: pointer;
      &:hover {
        border-color: color.$PRIMARY-BORDER;
      }
      > span {
        font-size: 14px;
      }
      > .chevronTenor {
        width: 12.313px;
        height: 12.488px;
        flex-shrink: 0;
        color: color.$NEUTRAL-100;
        transition: 0.3s;
      }
      > .chevronTenor[is-open="true"] {
        transform: rotate(180deg);
      }
      .wrapOpenDrpTenor {
        z-index: 99;
        position: absolute;
        width: 100%;
        height: fit-content;
        border-bottom-left-radius: 8px;
        border-bottom-right-radius: 8px;
        box-shadow: 0 1px 5px rgba(0, 0, 0, 0.388);
        background-color: color.$NEUTRAL-10;
        top: calc(100% + 0.2rem);
        left: 0;

        > ul li {
          font-size: 14px;
          text-align: left;
          list-style-type: none;
          padding: 6px 8px;
          cursor: pointer;
          &:hover {
            background-color: color.$PRIMARY-SURFACE;
          }
          &:not(:last-child) {
            border-bottom: 1px solid color.$NEUTRAL-50;
          }
        }
        > ul li[is-active="true"] {
          background-color: color.$PRIMARY;
          color: color.$NEUTRAL-10;
          &:last-child {
            border-bottom-left-radius: 8px;
            border-bottom-right-radius: 8px;
          }
        }
      }
    }
  }
}

.viewerVariant {
  max-height: 772px;
  height: auto;
  .itemVariant {
    width: 100%;
    aspect-ratio: 1;
    border: 0px;
    background-color: color.$NEUTRAL-10;
    transition: 0.2s;
    position: relative;
    // &:focus {
    //   background-color: color.$PRIMARY-SURFACE;

    //   .variantTag {
    //     background-color: color.$PRIMARY;
    //     span {
    //       color: color.$NEUTRAL-10;
    //     }
    //   }
    // }
    &:first-child {
      border-radius: 8px 0 0 0;

      .variantTag {
        border-radius: 8px 0 8px 0;
      }
    }

    .variantTag {
      padding: 4px 8px;
      position: absolute;
      left: 0;
      top: 0;
      background-color: color.$NEUTRAL-30;
      border-radius: 0px 0 8px 0;
      transition: 0.2s;

      span {
        color: color.$NEUTRAL-80;
        font-weight: 700;
      }
    }

    .variantImg {
      width: 70%;
      aspect-ratio: 1;
      transform: translateY(15px);
      object-fit: contain;
    }
  }
  .itemVariant[is-active-variant="true"] {
    background-color: color.$PRIMARY-SURFACE;
    .variantTag {
      background-color: color.$PRIMARY;
      span {
        color: color.$NEUTRAL-10;
      }
    }
  }

  .emptySpace {
    background-color: color.$NEUTRAL-10;
    flex: 1;
  }
}

.variantDiscount {
  height: 70px;
  width: 70px;
  // aspect-ratio: 1;
  flex-shrink: 0;
  border-radius: 50%;
  background: color.$DANGER;
  box-shadow: 0px 0px 20px 0px #00000033;
  position: absolute;
  right: 16px;
  top: 16px;

  span {
    font-size: calc(24em / 14);
    font-weight: 700;
    color: color.$NEUTRAL-10;
  }
}

.variantColors {
  gap: 4px;
  position: absolute;
  top: 16px;
  left: 16px;
  .colorSquare {
    height: 30px;
    aspect-ratio: 1;
    border: 2px solid transparent;
    box-shadow: 0 0 4px color.$NEUTRAL-50;
    position: relative;
    &:hover .toolTip {
      visibility: visible;
      opacity: 1;
      transform: translateX(0);
      // transition-delay: 0.3s;
    }
    .toolTip {
      position: absolute;
      display: flex;
      align-items: center;
      top: -4px;
      left: calc(100% + 1.5rem);
      transform: translateY(-50%);
      visibility: hidden;
      opacity: 0;
      white-space: nowrap;
      background-color: #363535;
      color: color.$NEUTRAL-10;
      // padding: 12px 16px;
      // font-size: 14px;
      padding: 8px 12px;
      border-radius: 4px;
      transition: visibility 0s, opacity 0.3s, transform 0.3s;
      z-index: 2;
      font-size: 14px;
      .popover {
        position: absolute;
        left: -10px;
        z-index: -1;
        flex-shrink: 0;
      }
    }
  }
  .colorSquare[is-active-color="true"] {
    position: relative;
    border-spacing: 10px;
    &::before {
      content: "";
      top: -5px;
      left: -5px;
      position: absolute;
      width: 100%;
      height: 100%;
      padding: 4px;
      border: 1px solid color.$NEUTRAL-100;
    }
  }
}

.viewerSimulation {
  border-radius: 0 8px 0 0;
  padding: 16px;
  gap: 24px;
  .simulationButton {
    cursor: pointer;
    background-color: color.$PRIMARY;
    padding: 8px 12px;
    color: color.$NEUTRAL-10;
    font-weight: 700;
    @include flexCenter();
    border-radius: 8px;
    font-size: 14px;
    &:hover {
      background-color: color.$PRIMARY-HOVER;
    }
  }
}

.viewerContent {
  position: relative;
}

.variantContent {
  width: 100%;
  // padding: 44px 111px 25px 111px;
}

.simHint {
  gap: 12px;
  border-radius: 8px;
  background: color.$PRIMARY-SURFACE;
  padding: 12px 12px 12px 14px;

  span {
    color: color.$PRIMARY;
    line-height: 20px;
  }
}

.simInfo {
  background: color.$PRIMARY;
  // width: 100%;
  padding: 2px;
  border-radius: 8px;
  max-width: 261px;
}

.infoCtrl {
  min-height: 52px;
  padding: 8px;
  img {
    width: 25px;
  }
  gap: 8px;

  &:not(:first-child) {
    background-color: #e7f3ff;
    border-radius: 6px;

    .infoDivider {
      background: color.$NEUTRAL-70;
    }

    .infoContent {
      label {
        color: color.$NEUTRAL-70;
      }
      P {
        color: color.$PRIMARY;
      }
    }
  }
}

.infoDivider {
  height: 20px;
  width: 1px;
  background: color.$NEUTRAL-10;
  border-radius: 0.5px;
}

.infoContent {
  label,
  p {
    color: color.$NEUTRAL-10;
  }
  label {
    font-size: calc(12em / 14);
  }
  p {
    margin: 0;
    padding-top: 2px;
    font-weight: 500;
  }
}

.variantBody {
  width: 100%;
  padding-inline: 40px;
  gap: 4px;
  h1 {
    margin: 0;
    font-size: 36px;
    color: color.$NEUTRAL-100;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 1;
    line-clamp: 1;
    overflow: hidden;
  }
  label {
    color: color.$NEUTRAL-70;
    font-weight: 500;
  }
  .variantTag {
    padding: 6px 12px;
    background: color.$PRIMARY;
    width: max-content;
    border-radius: 8px 0 8px 0;
    span {
      font-weight: 700;
      color: color.$NEUTRAL-10;
    }
  }

  .variantPrice {
    padding-top: 16px;
    gap: 4px;
    .finalPrice {
      span {
        color: color.$DANGER;
        font-weight: 700;
        font-size: calc(24em / 14);
      }
    }
    .basePrice {
      span {
        color: color.$NEUTRAL-50;
        font-weight: 500;
        font-size: calc(16em / 14);
        text-decoration: line-through;
      }
    }
  }
}
@media (max-width: 1024px) {
  .wrapperDetail {
    background-color: color.$NEUTRAL-10;
    justify-content: center;
    align-items: center;
    display: flex;
    flex-direction: column;
    border-top-right-radius: 8px;
    border-top-left-radius: 8px;
    .wrapperTabs {
      .tab {
        padding: 12px;
      }
    }
    .mainContent {
      row-gap: 16px;
      // padding: 16px 10px 16px 12px;
      width: 100%;
      justify-content: center;
      .iframeYoutube {
        @media (max-width: 460px) {
          width: 100%;
        }
        width: 100%;

        > iframe {
          width: 100%;
          height: 422px;
          flex-shrink: 0;
        }
      }
    }
    .viewerResponsive {
      @include flexCol(24px);
      border-top-right-radius: 8px;
      border-top-left-radius: 8px;
      background-color: color.$NEUTRAL-10;
      padding: 24px 16px;
      width: 90%;
      @media (max-width: 460px) {
        width: 100%;
      }
      .discount {
        content: "";
        position: absolute;
        display: flex;
        align-items: center;
        justify-content: center;
        top: 10px;
        right: 40px;
        width: 57px;
        height: 57px;
        border-radius: 50%;
        background-color: color.$DANGER;
        padding: 16px 10px 17px 10px;
        > span {
          font-weight: 700;
          color: color.$NEUTRAL-10;
        }
      }
      .imgMagnifier {
        display: flex;
        flex-direction: column;
        row-gap: 16px;
        justify-content: center;
        align-items: center;
        > img {
          width: 80%;
          height: 80%;
          object-fit: contain;
          max-width: 100%;
        }
        @media (max-width: 460px) {
          > img {
            width: 277px;
            height: 239px;
            object-fit: contain;
            max-width: 100%;
          }
        }
        .motorColor {
          display: flex;
          justify-content: center;
          gap: 4px;
          max-width: 100%;
          overflow: scroll;
          width: 100%;
          min-height: calc(34px + 4px);
          align-items: center;
          > button {
            width: 34px;
            height: 34px;
            border: none;
            flex-shrink: 0;
          }
          > button[is-active-color="true"] {
            position: relative;
            border-spacing: 10px;
            &::before {
              content: "";
              top: -2px;
              left: -2px;
              position: absolute;
              width: 30px;
              height: 30px;
              padding: 3px;
              border: 1px solid color.$NEUTRAL-100;
            }
          }
        }
      }
      .variant {
        display: flex;
        flex-direction: column;
        row-gap: 8px;
        > h4 {
          font-size: 14px;
          font-weight: 700;
        }
        .wrapVariant {
          display: flex;
          flex-wrap: wrap;
          gap: 8px;
          .nameVariant {
            border: 1px solid transparent;
            border-radius: 4px;
            display: flex;
            gap: 8px;
            align-items: center;
            padding: 4px 8px 4px 4px;
            width: fit-content;
            background-color: color.$NEUTRAL-30;
            transition: 0.2s ease-out;
            > img {
              width: 27px;
              height: 23px;
              object-fit: contain;
            }
            span {
              font-size: 12px;
              font-weight: 700;
              color: color.$NEUTRAL-70;
            }
          }
          .nameVariant[is-active-variant="true"] {
            background-color: color.$PRIMARY-SURFACE;
            border-color: color.$PRIMARY;
            > span {
              color: color.$PRIMARY;
            }
          }
        }
      }
      .description {
        @include flexCol(12px);
        .nameDesc {
          @include flexCol(4px);
          > h4 {
            font-weight: 700;
            font-size: 24px;
            display: -webkit-box;
            -webkit-box-orient: vertical;
            -webkit-line-clamp: 1;
            line-clamp: 1;
            overflow: hidden;
            line-height: 28px;
          }
          > span {
            font-size: 12px;
            color: color.$NEUTRAL-60;
          }
          > button {
            width: fit-content;
            padding: 6px 12px;
            background-color: color.$PRIMARY;
            color: color.$NEUTRAL-10;
            border-bottom-right-radius: 8px;
            border-top-left-radius: 8px;
            border: none;

            span {
              font-weight: 700;
            }
          }
        }
        > .price {
          @include flexCol(4px);
          > h4 {
            font-size: 24px;
            font-weight: 700;
            color: color.$DANGER;
          }
          > span {
            font-size: 16px;
            color: color.$NEUTRAL-50;
            text-decoration: line-through;
            font-weight: 500;
          }
        }
      }
    }
  }
}

@media (max-width: 900px) {
  .wrapperDetail {
    background-color: color.$NEUTRAL-10;
    justify-content: center;
    align-items: center;
    display: flex;
    flex-direction: column;
    border-top-right-radius: 8px;
    border-top-left-radius: 8px;
    .wrapperTabs {
      .tab {
        padding: 12px;
      }
    }
    .mainContent {
      row-gap: 16px;
      padding: 16px 10px 16px 12px;
      width: 100%;
      justify-content: center;
      @media (max-width: 460px) {
        width: 100%;
      }
      .content {
        width: 100% !important;
        display: flex !important;
        flex-direction: column !important;
        align-items: center !important;
        row-gap: 8px;

        > .img {
          width: 90%;
          @media (max-width: 460px) {
            width: 100%;
          }
          display: flex;
          align-items: center;
          justify-content: center;
          img {
            width: 100%;
            height: 100%;
            border-radius: 4px;
          }
        }

        > .paragraph {
          row-gap: 4px;
          width: 90%;
          @media (max-width: 460px) {
            width: 100%;
          }
          text-align: start;
          // @media (max-width: 460px) {
          //   width: 100%;
          // }
          > h3 {
            font-size: 1rem;
            font-weight: 700;
          }
          p {
            font-size: 14px;
            line-height: 20px;
          }
        }
      }
      .wrapperSpec {
        width: 90%;
        @media (max-width: 460px) {
          width: 100%;
        }
        .accordionSpec {
          padding: 12px;
          .header {
            gap: 12px;

            > img {
              width: 83px;
              height: 62px;
            }
            .wrapperHead {
              .rowHead {
                row-gap: 4px;
                p {
                  font-size: 12px;
                }
                .headAndIcon {
                  strong {
                    font-size: 16px !important;
                  }
                }
              }
            }
          }
          .wrapperDescription {
            .desc {
              > ul li {
                &:first-child {
                  padding-top: 8px;
                }
                padding: 0 0 12px 8px;
                font-weight: 500;
              }
            }
          }
        }
      }
      .iframeYoutube {
        @media (max-width: 460px) {
          width: 100%;
        }
        width: 90%;

        > iframe {
          width: 100%;
          height: 422px;
          flex-shrink: 0;
        }
      }
    }
    .viewerResponsive {
      @include flexCol(24px);
      border-top-right-radius: 8px;
      border-top-left-radius: 8px;
      background-color: color.$NEUTRAL-10;
      padding: 24px 16px;
      width: 90%;
      @media (max-width: 460px) {
        width: 100%;
      }
      .discount {
        content: "";
        position: absolute;
        display: flex;
        align-items: center;
        justify-content: center;
        top: 10px;
        right: 40px;
        width: 57px;
        height: 57px;
        border-radius: 50%;
        background-color: color.$DANGER;
        padding: 16px 10px 17px 10px;
        > span {
          font-weight: 700;
          color: color.$NEUTRAL-10;
        }
      }
      .imgMagnifier {
        display: flex;
        flex-direction: column;
        row-gap: 16px;
        justify-content: center;
        align-items: center;
        > img {
          width: 80%;
          height: 80%;
          object-fit: contain;
          max-width: 100%;
        }
        @media (max-width: 460px) {
          > img {
            width: 277px;
            height: 239px;
            object-fit: contain;
            max-width: 100%;
          }
        }
        .motorColor {
          display: flex;
          justify-content: center;
          gap: 4px;
          max-width: 100%;
          overflow: scroll;
          width: 100%;
          min-height: calc(34px + 4px);
          align-items: center;
          > button {
            width: 34px;
            height: 34px;
            border: none;
            flex-shrink: 0;
          }
          > button[is-active-color="true"] {
            position: relative;
            border-spacing: 10px;
            &::before {
              content: "";
              top: -2px;
              left: -2px;
              position: absolute;
              width: 30px;
              height: 30px;
              padding: 3px;
              border: 1px solid color.$NEUTRAL-100;
            }
          }
        }
      }
      .variant {
        display: flex;
        flex-direction: column;
        row-gap: 8px;
        > h4 {
          font-size: 14px;
          font-weight: 700;
        }
        .wrapVariant {
          display: flex;
          flex-wrap: wrap;
          gap: 8px;
          .nameVariant {
            border: 1px solid transparent;
            border-radius: 4px;
            display: flex;
            gap: 8px;
            align-items: center;
            padding: 4px 8px 4px 4px;
            width: fit-content;
            background-color: color.$NEUTRAL-30;
            transition: 0.2s ease-out;
            > img {
              width: 27px;
              height: 23px;
              object-fit: contain;
            }
            span {
              font-size: 12px;
              font-weight: 700;
              color: color.$NEUTRAL-70;
            }
          }
          .nameVariant[is-active-variant="true"] {
            background-color: color.$PRIMARY-SURFACE;
            border-color: color.$PRIMARY;
            > span {
              color: color.$PRIMARY;
            }
          }
        }
      }
      .description {
        @include flexCol(12px);
        .nameDesc {
          @include flexCol(4px);
          > h4 {
            font-weight: 700;
            font-size: 24px;
            display: -webkit-box;
            -webkit-box-orient: vertical;
            -webkit-line-clamp: 1;
            line-clamp: 1;
            overflow: hidden;
            line-height: 28px;
          }
          > span {
            font-size: 12px;
            color: color.$NEUTRAL-60;
          }
          > button {
            width: fit-content;
            padding: 6px 12px;
            background-color: color.$PRIMARY;
            color: color.$NEUTRAL-10;
            border-bottom-right-radius: 8px;
            border-top-left-radius: 8px;
            border: none;

            span {
              font-weight: 700;
            }
          }
        }
        > .price {
          @include flexCol(4px);
          > h4 {
            font-size: 24px;
            font-weight: 700;
            color: color.$DANGER;
          }
          > span {
            font-size: 16px;
            color: color.$NEUTRAL-50;
            text-decoration: line-through;
            font-weight: 500;
          }
        }
      }
    }
  }
}
