@import "../../../../themes/color";

.carouselBody {
  display: flex;
  flex-direction: column;
  align-self: center;
  gap: 24px;
  overflow: hidden;
  width: fit-content;
}

.innerActive {
  display: block;
}

.innerInactive {
  display: none;
}

.slides {
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-radius: 16px;
  min-width: calc(672px * 2);
  background: url("../../../../assets/img/logo-yamaha.png");
  background-size: contain;
  overflow: hidden;
  background-color: #f3f3f3;
  .descBox {
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding-left: 84px;
    gap: 8px;
    max-width: 672px;
    // width: 100%;
    max-height: 640px;
    // height: 100%;

    .name {
      color: $NEUTRAL-100;
      // font-size: calc(14px + (48 - 14) * ((100vw - 280px) / (1600 - 280)));
      font-size: 48px;
      font-weight: 700;
      animation: fadeIn 3s;
    }
    .desc {
      color: $NEUTRAL-100;
      font-size: 1rem;
      font-weight: 400;
      line-height: 24px;
      width: 90%;
      animation: fadeIn 3s;
    }
  }
  .imageBox {
    width: 672px;
    height: 100%;
    img {
      width: 100%;
      height: 100%;
      border-top-right-radius: 8px;
      border-bottom-right-radius: 8px;
    }
  }
}

.inner {
  position: relative;
}

.chevronLeft {
  position: absolute;
  top: 45%;
  left: 16px;
  cursor: pointer;
  background: rgba(33, 33, 33, 0.5);
  backdrop-filter: blur(8px);
  border-radius: 50px;
  padding: 13px;
  display: flex;
  justify-content: center;
  transition: none;
}

.chevronRight {
  display: flex;
  justify-content: center;
  position: absolute;
  right: 16px;
  top: 45%;
  cursor: pointer;
  background: rgba(33, 33, 33, 0.5);
  backdrop-filter: blur(8px);
  border-radius: 50px;
  padding: 13px;
  transition: none;
  z-index: 1;
}

@keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

@media (max-width: 768px) {
  .slides {
    flex-direction: column;
    min-width: 0;
    background-position: top -70px right 0;
    .descBox {
      width: 100%;
      padding: 0 16px;
      min-height: 171px;
      row-gap: 8px;
      .name {
        font-size: 16px;
        font-weight: 700;
      }
      .desc {
        font-size: 14px;
        line-height: 20px;
      }
    }
    .imageBox {
      width: 100%;
      height: fit-content;
      object-fit: cover !important;
      img {
        border-top-right-radius: 0;
        border-bottom-left-radius: 8px;
      }
    }
  }
  .chevronLeft {
    top: 60% !important;
    left: 0;
  }
  .chevronRight {
    top: 60% !important;
    right: 0;
  }
}
.radioButton {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 8px;
}
@media (max-width: 1024px) {
  .slides {
    min-width: 0;
    .descBox {
      max-width: 672px;
      width: 100%;
      height: 100%;
      .name {
        font-size: 24px;
      }
    }
  }
  .chevronLeft {
    top: 40%;
    left: 0;
  }
  .chevronRight {
    top: 40%;
    right: 0;
  }
}
