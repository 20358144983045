@use "themes/color";
@import "../../../../themes/global";
.wrapperOpenItems,
.wrapperOpenCategory,
.wrapperOpenRangePrice {
  width: 100%;
  position: fixed;
  bottom: 0;
  background-color: color.$NEUTRAL-10;
  z-index: 999999;
  transform: translateY(100%);
  transition: 0.4s;
  padding: 0 16px 24px 16px;
  border-top-left-radius: 20px;
  border-top-right-radius: 20px;
  @include flexCol(24px);
  .contentItems {
    width: 100%;
    display: flex;
    flex-direction: column;
    padding: 0;
    margin: 0;
    &:last-child {
      row-gap: 1rem;
    }
    .header {
      @include flexCol(12px);
      align-items: center;
      background-color: transparent;
      padding: 5px !important;
      width: 100%;
      height: fit-content;
      margin-bottom: 16px;
      .line {
        width: 42px;
        height: 4px;
        border-radius: 8px;
        background-color: color.$NEUTRAL-60;
      }
      > div {
        width: 100%;
        @include flexBetween;
        text-align: center;
        > span {
          font-size: 16px;
          font-weight: 700;
          color: color.$NEUTRAL-100;
        }
        > .closeIcon {
          width: 24px;
          height: 24px;
          flex-shrink: 0;
          cursor: pointer;
        }
      }
    }
    .wrapRadio {
      @include flexCol(8px);
      > div:nth-child(1) {
        width: 100%;
        @include flexBetween();
        > span {
          &:nth-child(1) {
            font-size: 14px;
          }
          &:nth-child(2) {
            font-weight: 700;
          }
        }
      }
      .childList {
        @include flexBetween();

        .action {
          padding: 0 !important;
          @include flex(10px);
          align-items: center;
          .radioCheckbox {
            width: 24px;
            height: 24px;
            flex-shrink: 0;
          }
          > span {
            font-size: 14px;
          }
        }
      }
    }
    .headerRange {
      width: 100%;
      @include flexCol(1rem);
      color: color.$NEUTRAL-100;
      > span {
        font-size: 1rem;
        font-weight: 700;
      }
      > div {
        width: 100%;
        @include flexBetween;
        > span {
          font-size: 14px;
        }
      }
    }
    .rangeMobile {
      @include flexCol(16px);
      .buttonPrice {
        display: flex;
        align-items: center;
        .line {
          width: 12px;
          height: 4px;
          background-color: color.$PRIMARY;
          border-radius: 8px;
        }
        @include flexBetween;
        .btnMin,
        .btnMax {
          padding: 12px 1rem;
          border: 1px solid color.$NEUTRAL-40;
          border-radius: 8px;
          width: 165px;

          span {
            display: flex;
            gap: 4px;
            overflow: hidden;
            > input {
              border: none;
              outline: none;
              background-color: transparent;
              color: color.$NEUTRAL-100;
              font-size: 1rem;
              font-weight: 500;
            }
          }
        }
      }
    }
  }
  .buttonItems {
    width: 100%;
    background-color: color.$PRIMARY;
    border-radius: 8px;
    display: flex;
    justify-content: center;
    padding: 8px 12px;
    &:hover {
      background-color: color.$PRIMARY-HOVER;
    }
    > span {
      font-size: 14px;
      color: color.$NEUTRAL-10;
      font-weight: 700;
    }
  }
}
.backdropContain {
  position: fixed;
  top: 0;
  height: 90%;
  width: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 1;
  transition: 0.2s;
}
.wrapperOpenItems[is-open="true"],
.wrapperOpenCategory[is-open="true"],
.wrapperOpenRangePrice[is-open="true"] {
  transform: translateY(0);
}
// .backdropContain {
//   height: 100vh;
//   background-color: salmon;
//   position: absolute;
//   z-index: 99999;
// }
.wrapperFilMobile {
  // &::before {
  //   content: "";
  //   position: absolute;
  //   width: 100%;
  //   height: 100vh;
  //   background-color: salmon;
  //   z-index: -2;
  // }
  display: flex;
  position: fixed;
  bottom: 0;
  z-index: 9999;
  width: 100%;
  left: 0;
  background-color: color.$NEUTRAL-10;
  padding: 1rem;
  align-items: center;
  height: 72px;
  border-top: 1px solid color.$NEUTRAL-40;
  > .filter {
    justify-content: center;

    overflow: scroll;
    min-width: 335px;
    width: 100%;
    display: flex;
    gap: 2rem;
    padding-right: 1rem;
    padding-left: 1rem;
    @media (max-width: 460px) {
      justify-content: flex-start;
      gap: 8px;
      padding-right: 4rem;
      padding-left: 0;
    }
    @media (max-width: 390px) {
      padding-right: 3rem;
      padding-left: 0;
    }
    @media (max-width: 360px) {
      padding-right: 4rem;
      padding-left: 0;
    }
    > div {
      padding: 8px 12px;
      display: flex;
      border: 1px solid color.$NEUTRAL-40;
      border-radius: 40px;
      min-width: fit-content;
      height: fit-content;
      gap: 12px;
      align-items: center;
      span {
        color: color.$NEUTRAL-100;
        font-size: 14px;
      }
    }
  }
  .refreshIcon {
    width: 56px;
    position: fixed;
    bottom: 0;
    right: 0;
    border-left: 1px solid color.$NEUTRAL-40;
    padding: 1rem;
    display: flex;
    align-items: center;
    background-color: color.$NEUTRAL-10;
    height: 72px;
    border-top: 1px solid color.$NEUTRAL-40;
    form {
      > button {
        border: none;
        background-color: transparent;
      }
      > span {
        height: fit-content;
      }
    }
  }
}
.container {
  width: 100vw;
  min-height: 100vh;
  background-color: color.$NEUTRAL-20;
  @media (max-width: 768px) {
  }
}

.header {
  background-color: #2b2b2b;
  height: 182px;
  padding-top: 32px;
  padding-bottom: 80px;
  padding-inline: 50px;
  width: 100%;
  gap: 8px;

  h2,
  span {
    margin: 0;
    color: color.$NEUTRAL-10;
    max-width: 1300px;
  }

  h2 {
    font-size: calc(24em / 14);
  }

  span {
    font-size: calc(16em / 14);
  }

  @media (max-width: 576px) {
    padding-inline: 25px;
  }
}
//

.aligner {
  width: 100%;
  max-width: 1400px;
  padding-inline: 50px;
  transform: translateY(-40px);
  @media (max-width: 576px) {
    padding-inline: 16px;
  }
}
//
.whiteBg {
  width: 100%;
  min-height: 70vh;
  background-color: color.$NEUTRAL-10;
  position: relative;
}

.filterSec {
  width: 272px;
  border-right: 1px solid color.$NEUTRAL-40;
  transition: 0.3s;
  position: relative;

  @media (max-width: 1024px) {
    pointer-events: none;
    // width: 0;
    opacity: 0;
    position: fixed;
    bottom: 0;
    z-index: 99;
    transform: translateX(-272px);
    &.slide {
      transform: translateX(0);
      opacity: 1;
      pointer-events: all;
    }
  }
}

.filterMain {
  flex: 1;
  transition: 0.3s;

  @media (max-width: 1024px) {
    &.slide {
      transform: translateX(272px);
    }
  }
}

.filterHeader {
  height: 60px;
  border-bottom: 1px solid color.$NEUTRAL-40;

  .filterRowCtrl {
    &:first-child {
      flex: 1.5;
    }
    &:not(:first-child) {
      border-left: 1px solid color.$NEUTRAL-40;
      flex: 1;
    }
    .chevronPrice {
      transition: 0.3s;
    }
    .chevronPrice[is-open-sort-price="true"] {
      transform: rotate(180deg);
    }
    .chevronPrice[is-open-sort-asc="true"] {
      transform: rotate(180deg);
    }
    .chevronPrice[is-open-sort-display="true"] {
      transform: rotate(180deg);
    }
  }
}

.filterTitle {
  flex: 1;
}

.filterRefresh {
  border-left: 1px solid color.$NEUTRAL-40 !important;
  background-color: color.$NEUTRAL-10;
  width: 60px;
  border: 0;
  .refreshIcon {
    transition: 0.6s;
  }
  &:hover {
    .refreshIcon {
      display: flex;
      flex-shrink: 0;
      transform: rotate(360deg);
    }
  }
}
.wrapeperPagination {
  width: 100%;
  background-color: color.$NEUTRAL-10;
  .seperators {
    border-right: 1px solid color.$NEUTRAL-40;
    width: 26.45%;
    border-top: 1px solid color.$NEUTRAL-40;
    @media (max-width: 1024px) {
      width: 0;
      border: none;
    }
  }
}
.filterFooter {
  height: 60px;
  width: 100%;
  border-top: 1px solid color.$NEUTRAL-40;
  background-color: color.$NEUTRAL-10;
  & > button {
    border: 0;
    background-color: color.$NEUTRAL-10;
  }

  .filterRowCtrl {
    &:first-child {
      width: 56px;
    }
    &:not(:first-child) {
      border-left: 1px solid color.$NEUTRAL-40;
    }
    &:not(:first-child, :last-child) {
      flex: 1;
    }
    &:last-child {
      width: 56px;
    }
  }
}

.filterContent {
  flex: 1;
  @media (max-width: 768px) {
    min-height: 0;
  }
  // min-height: 86vh;
}

.filterColCtrl {
  min-height: calc(172px - 2rem);
  height: fit-content;
  gap: 16px;
  &:not(:first-child) {
    border-top: 1px solid color.$NEUTRAL-40;
  }
  h3 {
    margin: 0;
  }
}

.filterPaddingCtrl {
  padding: 24px 16px;
  @include flexCol(1rem);
}
.filterPaddingCtrl:first-child {
  color: color.$NEUTRAL-100;
  > .listItems {
    @include flexCol(8px);
    font-size: 14px;

    .topList {
      @include flexBetween;
    }
    .childList {
      @include flexBetween;
      .action {
        @include flex(10px);
        align-items: center;
        .radioCheckbox {
          width: 24px;
          height: 24px;
          flex-shrink: 0;
          cursor: pointer;
        }
      }
    }
  }
}
.filterPaddingCtrl:nth-child(2) {
  color: color.$NEUTRAL-100;
  row-gap: 8px;
  > .listItems {
    @include flexCol(8px);
    font-size: 14px;
    .topList {
      @include flexBetween;
    }
    .childList {
      @include flexBetween;
      .action {
        @include flex(10px);
        align-items: center;
        .checkboxIcon {
          width: 24px;
          height: 24px;
          flex-shrink: 0;
          cursor: pointer;
        }
      }
    }
  }
}
.filterPaddingCtrl:nth-child(3) {
  .priceRange {
    @include flexCol(8px);
    > div:nth-child(1) {
      @include flexBetween;
    }
    input[type="range"] {
      width: 100%;
      cursor: pointer;
    }
  }
  .buttonPrice {
    @include flex(8px);
    align-items: center;
    .btnMin,
    .btnMax {
      min-width: 108px;
      padding: 0.8rem 1rem;
      border-radius: 8px;
      border: 1px solid color.$NEUTRAL-50;
      color: color.$NEUTRAL-70;
      &:hover {
        background-color: color.$NEUTRAL-30;
      }
      > span {
        overflow: hidden;
        display: flex;
        gap: 4px;
        > input {
          border: none;
          outline: none;
          background-color: transparent;
          color: color.$NEUTRAL-100;
          font-size: 1rem;
          font-weight: 500;
        }
      }
    }
    .untilRange {
      padding-inline: 8px;
      height: 4px;
      border-radius: 8px;
      background-color: color.$PRIMARY;
    }
  }
}
.filterBtn {
  height: 100%;
  width: 100%;
  padding-inline: 16px;
  background-color: color.$NEUTRAL-10;
  border: 0;

  label {
    font-weight: 500;
    pointer-events: none;
    font-size: 1em;
  }

  span {
    font-weight: 400;
  }
}
.filterRowCtrl[nth="2"] {
  position: relative;
  cursor: pointer;
  .dropdownPrice {
    position: absolute;
    z-index: 99;
    left: 0;
    top: 60px;
    background-color: color.$NEUTRAL-10;
    width: 100%;
    height: fit-content;
    border-bottom-right-radius: 8px;
    border-bottom-left-radius: 8px;
    box-shadow: 0 2px 10px color.$NEUTRAL-50;
    .list {
      @include flexCol(0);
      padding: 6px 8px;
      font-size: 14px;
      color: color.$NEUTRAL-100;
      &:not(:nth-child(4)) {
        border-bottom: 1px solid color.$NEUTRAL-30;
      }
      &:hover {
        background-color: color.$PRIMARY-SURFACE;
        cursor: pointer;
      }
    }
    .list[is-active="true"] {
      background-color: color.$PRIMARY-PRESSED;
      color: color.$NEUTRAL-10;
    }
    .list:nth-child(4)[is-active="true"] {
      border-bottom-right-radius: 8px;
      border-bottom-left-radius: 8px;
    }
  }
}
.filterRowCtrl[nth="3"] {
  position: relative;
  cursor: pointer;
  .dropdownAsc {
    position: absolute;
    z-index: 99;
    left: 0;
    top: 60px;
    background-color: color.$NEUTRAL-10;
    width: 100%;
    height: fit-content;
    border-bottom-right-radius: 8px;
    border-bottom-left-radius: 8px;
    box-shadow: 0 2px 10px color.$NEUTRAL-50;
    .list {
      @include flexCol(0);
      padding: 6px 8px;
      font-size: 14px;
      color: color.$NEUTRAL-100;
      &:not(:nth-child(2)) {
        border-bottom: 1px solid color.$NEUTRAL-30;
      }
      &:hover {
        background-color: color.$PRIMARY-SURFACE;
        cursor: pointer;
      }
    }
    .list[is-active="true"] {
      background-color: color.$PRIMARY-PRESSED;
      color: color.$NEUTRAL-10;
    }
    .list:last-child[is-active="true"] {
      border-bottom-right-radius: 8px;
      border-bottom-left-radius: 8px;
    }
  }
}
.filterRowCtrl[nth="4"] {
  position: relative;
  cursor: pointer;
  .dropdownDisplay {
    position: absolute;
    z-index: 99;
    left: 0;
    top: 60px;
    background-color: color.$NEUTRAL-10;
    width: 100%;
    height: fit-content;
    border-bottom-right-radius: 8px;
    border-bottom-left-radius: 8px;
    box-shadow: 0 2px 10px color.$NEUTRAL-50;
    .list {
      @include flexCol(0);
      padding: 6px 8px;
      font-size: 14px;
      color: color.$NEUTRAL-100;
      &:not(:nth-child(4)) {
        border-bottom: 1px solid color.$NEUTRAL-30;
      }
      &:hover {
        background-color: color.$PRIMARY-SURFACE;
        cursor: pointer;
      }
    }
    .list[is-active="true"] {
      background-color: color.$PRIMARY-PRESSED;
      color: color.$NEUTRAL-10;
    }
    .list:last-child[is-active="true"] {
      border-bottom-right-radius: 8px;
      border-bottom-left-radius: 8px;
    }
  }
}
.filterCtrl {
  padding-inline: 16px;
}

.filterGrid {
  // display: grid;
  // grid-template-columns: auto auto auto;
  // grid-template-rows: 1fr 1fr 1fr 1fr;
  display: flex;
  flex-wrap: wrap;
  background-color: color.$NEUTRAL-20;
  row-gap: 1px;
  border-right: 1px solid color.$NEUTRAL-10;
}

.gridItem {
  background-color: color.$NEUTRAL-10;
  min-height: 250px;
  position: relative;
  max-width: 342px;
  min-width: 300px;
  flex-grow: 1;
  height: 280px;
  @media (max-width: 1000px) {
    min-height: 230px;
    min-width: 50%;
  }
}

.gridClickable {
  transition: 0.3s;
  position: absolute;
  height: 100%;
  width: 100%;
  top: 0;
  left: 0;
  padding: 12px 10px;
  transition: 0.3s;

  h4,
  label,
  span,
  h5 {
    transition: 0.3s;
  }

  &:hover {
    background-color: #00000080;
    backdrop-filter: blur(3px);

    h4,
    label,
    span,
    h5 {
      color: color.$NEUTRAL-10 !important;
    }
    .itemColor {
      filter: blur(3px);
    }
    .itemDisc {
      transition: 0.3s;
      background-color: color.$NEUTRAL-10;
      span {
        color: color.$DANGER !important;
      }
    }
    .gridButton {
      opacity: 1;
      span {
        color: color.$PRIMARY-HOVER !important;
      }
    }
  }
}

.gridHead {
  .itemTitle {
    h4 {
      margin: 0;
      overflow: hidden;
      display: -webkit-box;
      -webkit-line-clamp: 2;
      line-clamp: 2;
      -webkit-box-orient: vertical;
    }
    label {
      color: color.$NEUTRAL_50;
      overflow: hidden;
    }
  }

  .itemDisc {
    height: 44px;
    aspect-ratio: 1;
    background-color: color.$DANGER;
    border-radius: 50%;
    span {
      color: color.$NEUTRAL-10;
      font-weight: 700;
    }
  }
}

.gridBody {
  flex: 1;
  position: relative;
  height: 100%;
  width: 100%;
  gap: 10px;
}

.gridButton {
  opacity: 0;
  height: 48px;
  width: 117px;
  background-color: color.$NEUTRAL-10;
  border-radius: 8px;
  border: 0;
  transition: 0.3s;
  user-select: none;
  span {
    font-weight: 700;
    font-size: calc(16em / 14);
    color: color.$PRIMARY-HOVER;
  }
}

.gridFoot {
  .itemPrice {
    h4,
    h5 {
      margin: 0;
    }
    h4 {
      color: color.$DANGER;
    }
    h5 {
      color: color.$NEUTRAL_50;
      margin-top: 2px;
      text-decoration: line-through;
    }
  }

  .itemColor {
    gap: 2px;
    transition: 0.3s;
    .colorBullet {
      width: 24px;
      height: 22px;
      transform: skewX(-30deg);
      perspective: 20px;
      border-top-left-radius: 3px;
      border-top-right-radius: 8px;
      border-bottom-left-radius: 8px;
      border-bottom-right-radius: 3px;
    }
    span {
      margin-left: 4px;
      color: color.$PRIMARY !important;
      font-weight: 700;
    }
  }
}
@media (max-width: 768px) {
  .filterMain {
    .filterHeader[is-mobile="true"] {
      // padding: 32px 12px;
      flex-direction: column;
      height: auto;
      .filterRowCtrl {
        border: none;
        &:first-child {
          padding: 32px 12px 16px 12px;
        }
        &:nth-child(2),
        &:nth-child(3),
        &:nth-child(4) {
          padding: 8px 12px;
          .dropdownPrice,
          .dropdownAsc,
          .dropdownDisplay {
            top: 32px;
          }
          &:not(:last-child) {
            border-bottom: 1px solid color.$NEUTRAL-40;
          }
          > .filterBtn {
            padding: 0;
          }
        }
      }
    }
  }
  .gridItem {
    min-width: 100%;
  }
  .filterFooter {
    min-width: 340px;
    width: 100%;
    @media (max-width: 390px) {
      min-width: 320px;
      width: 100%;
    }
    @media (max-width: 360px) {
      min-width: 280px;
      width: 100%;
    }
    .filterCtrl {
      padding: 0 !important;
    }
    .filterPageBtn {
      @media (max-width: 400px) {
        width: calc(56px - 20px) !important;
        padding: 0;
      }
    }
  }
  .filterSec {
    position: fixed;
    bottom: 0;
  }
}
//
