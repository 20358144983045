.flex {
  display: flex;
}

.flex-center {
  display: flex;
  justify-content: center;
  align-items: center;
}

.flex-justify-center {
  display: flex;
  justify-content: center;
}

.flex-align-center {
  display: flex;
  align-items: center;
}

.flex-row {
  display: flex;
  flex-direction: row;
}

.flex-row-reverse {
  display: flex;
  flex-direction: row-reverse;
}

.flex-column {
  display: flex;
  flex-direction: column;
}

.flex-column-align-center {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.flex-reverse-center {
  display: flex;
  align-items: center;
  flex-direction: row-reverse;
}

.flex-between {
  display: flex;
  justify-content: space-between;
}

.flex-between-center {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.none {
  display: none;
}
@mixin flex($gap) {
  display: flex;
  flex-direction: row;
  gap: $gap;
}

@mixin flexCol($rowGap) {
  display: flex;
  flex-direction: column;
  row-gap: $rowGap;
}

@mixin flexBetween() {
  display: flex;
  justify-content: space-between;
}

@mixin flexColumnAlignCenter {
  display: flex;
  flex-direction: column;
  align-items: center;
}

@mixin flexCenter() {
  display: flex;
  justify-content: center;
  align-items: center;
}
