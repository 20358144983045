@import "../../../../themes/color";
@import "../../../../themes/global";
.wrapperTopLandingpage {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  .imgBackground {
    margin-top: -86px;
    // max-width: 1440px;
    width: 100%;
    height: 100%;
    height: calc(768px + 12px);
    background: url("../../../../assets/img/background-landing-page.png");
    position: relative;
    z-index: 1;
    // background-repeat: repeat-x;
    background-repeat: round;
    &::after {
      content: "";
      height: 210px;
      width: 100%;
      background: rgba(63, 63, 63, 0.691);
      position: absolute;
      bottom: 0;
      z-index: -1;
      backdrop-filter: blur(5px);
      @media (max-width: 900px) {
        height: 157px;
        background: rgba(0, 0, 0, 0);
      }
    }
    .wrapperContain {
      display: flex;
      gap: 22px;
      padding-top: 86px;
      background-color: #00000099;
      height: 100%;
      width: 100%;
      .leftArea {
        padding-top: 7px;
        width: 40%;
        height: auto;
        display: flex;
        overflow: hidden;
        > img[alt="motor"] {
          // transition: 0.3s ease-out;
          width: 766px;
          height: 675px;
          flex-shrink: 0;
          transform: translateX(-192px);
        }
        > img[alt="motor"][is-move="true"] {
          transform: translateX(-100%);
          transition: 0.4s;
        }
        > img[alt="motor"][is-move="false"] {
          transform: translateX(-192px);
          transition: 0.6s ease-out;
        }
      }
      .rightArea {
        width: 60%;
        height: 100%;
        padding-top: 57px;
        padding-right: 48px;
        justify-content: space-between;
        @include flexCol(64px);
        .topDecription {
          @include flexCol(32px);
          max-width: 760px;
          width: 100%;
          font-family: Roboto;
          @media (min-width: 768px) {
            height: 352px;
          }
          justify-content: space-between;
          > div {
            @include flexCol(0);

            @media (min-widthl: 768px) {
              @include flexCol(32px);
            }
            h1 {
              color: $NEUTRAL-10;
              font-size: 60px;
              font-weight: 700;
              line-height: 80px;
              display: -webkit-box;
              display: -moz-box;
              -webkit-box-orient: vertical;
              -moz-box-orient: vertical;
              line-clamp: 1;
              -webkit-line-clamp: 1;
              overflow: hidden;
            }
            > p {
              color: $NEUTRAL-20;
              font-size: 20px;
              font-weight: 300;
              line-height: 33px;
              display: -webkit-box;
              display: -moz-box;
              -webkit-box-orient: vertical;
              -moz-box-orient: vertical;
              overflow: hidden;
              line-clamp: 4;
              -webkit-line-clamp: 4;
            }
            span {
              color: $NEUTRAL-10;
              font-size: 24px;
              line-height: 28px;
            }
          }

          > .button {
            padding: 12px 16px;
            border-radius: 8px;
            background: linear-gradient(90deg, #1f5d97 0%, #4390d8 100%);
            display: flex;
            justify-content: center;
            width: 150px;
            cursor: pointer;
            align-items: center;
            &:hover {
              background: $PRIMARY;
            }
            &:active {
              background: $PRIMARY-PRESSED;
            }
            > span {
              color: $NEUTRAL-10;
              font-size: 1rem;
              font-weight: 700;
            }
          }
        }
        .wrapperFoot {
          display: flex;
          height: 210px;
          .footDescription {
            width: 254px;
            height: 100%;
            @include flexCol(0);
            justify-content: space-between;
            // background: rgba(20, 19, 26, 0.6);
            padding: 12px;
            > div:nth-child(1) {
              display: flex;
              align-items: center;
              gap: 8px;
              > h2 {
                color: $NEUTRAL-70;
                font-family: Roboto;
                font-size: 24px;
                font-weight: 700;
                line-height: 28px;
              }
              > .dividerLoad[is-active="true"] {
                width: 0;
                height: 2px;
                background-color: $PRIMARY;
              }
              > .dividerLoad[is-active="false"] {
                transition: none !important;
              }
              > .dividerLoad {
                width: 0;
                height: 0;
                background-color: transparent;
                transition: none;
              }
            }
            .nameAndPrice {
              @include flexCol(4px);
              h3 {
                color: $NEUTRAL-70;
                font-family: Roboto;
                font-size: 24px;
                font-weight: 700;
                line-height: 28px;
                overflow: hidden;
                display: -webkit-box;
                -webkit-box-orient: vertical;
                -moz-box-orient: vertical;
                display: -moz-box;
                line-clamp: 2;
                -webkit-line-clamp: 2;
              }
              span {
                color: $NEUTRAL-60;
                font-weight: 700;
                font-size: 16px;
              }
            }
          }
          .footDescription[is-active="true"] {
            background-color: #00000040;
            backdrop-filter: blur(5px);
            > div:nth-child(1) {
              > h2 {
                color: $NEUTRAL-10;
              }
            }
            .nameAndPrice {
              h3 {
                color: $NEUTRAL-10;
              }
            }
          }
        }
      }
    }
  }
}
@media (max-width: 1024px) {
  .wrapperTopLandingpage {
    .imgBackground {
      position: absolute;
      background-repeat: no-repeat;
      max-height: 698px;
      height: auto;
      backdrop-filter: blur(5px);
      .wrapperContain {
        gap: 16px;
        .leftArea {
          width: 50%;
          display: flex;
          align-items: center;
          > img[alt="motor"] {
            width: 566px;
            height: 475px;
            flex-shrink: 0;
            transform: translateX(-162px);
          }
        }
        > .rightArea {
          width: 50%;
          row-gap: 0;
          padding-right: 0;
          row-gap: 1rem;
          .topDecription {
            row-gap: 1rem;
            padding-right: 12px;
            // row-gap: 32px;
            > div {
              h1 {
                font-size: 32px;
                line-height: normal;
              }
              span {
                font-size: 16px;
                line-height: normal;
              }
            }
            > p {
              font-size: 20px;
              line-height: normal;
            }
          }
          .wrapperFoot {
            width: 100%;
            height: auto;
            .footDescription {
              width: 100%;
              height: 150px;
              max-width: unset;
              > div:nth-child(1) {
                h2 {
                  font-size: 24px;
                }
              }
              .nameAndPrice {
                h3 {
                  font-size: 16px;
                  line-height: normal;
                }
              }
            }
          }
        }
      }
    }
  }
}
@media (max-width: 600px) {
  .wrapperTopLandingpage {
    .imgBackground {
      width: 100%;
      background-size: fill;
      background-repeat: no-repeat;
      background-position: top 0 left 0;
      margin-top: 0;
      backdrop-filter: blur(5px);
      .wrapperContain {
        overflow: hidden;
        height: 100%;
        display: flex;
        flex-direction: column;
        height: auto;
        padding-top: 24px;
        row-gap: 0;
        .leftArea {
          order: 2;
          width: 100%;
          display: flex;
          flex-direction: column;
          align-items: center;
          row-gap: 16px;
          > img[alt="motor"] {
            padding-inline: 32px;
            min-width: 273px !important;
            min-height: 241px !important;
            width: 100%;
            height: 100%;
            max-width: 350px;
            max-height: 300px;
            transform: translateX(0) !important;
          }
          > img[alt="motor"][is-move="true"] {
            transform: translateX(-100%) !important;
          }
          .buttonMobile {
            padding: 8px 12px;
            border-radius: 8px;
            background: linear-gradient(90deg, #1f5d97 0%, #4390d8 100%);
            display: flex;
            justify-content: center;
            width: fit-content;
            cursor: pointer;
            &:hover {
              background: $PRIMARY;
            }
            &:active {
              background: $PRIMARY-PRESSED;
            }
            > span {
              color: $NEUTRAL-10;
              font-size: 14px;
              font-weight: 700;
            }
          }
          .wrapperFootMobile {
            display: flex;
            backdrop-filter: blur(5px);
            .footDescription {
              height: 157px;
              width: 130.6px;
              display: flex;
              flex-direction: column;
              justify-content: space-between;
              padding: 12px;
              > div:nth-child(1) {
                display: flex;
                align-items: center;
                gap: 4px;
                color: $NEUTRAL-10;
                transition: 0.3s;
                h2 {
                  font-size: 24px;
                  line-height: 28px;
                  color: $NEUTRAL-70;
                }
                > .dividerLoad {
                  height: 2px;
                  width: 100%;
                  background-color: transparent;
                }
              }
              > div:nth-child(1)[is-active="true"] {
                h2 {
                  color: $NEUTRAL-10;
                }
                > .dividerLoad[is-active="true"] {
                  width: 0;
                  height: 2px;
                  background-color: $PRIMARY;
                }
                > .dividerLoad {
                  width: 0;
                  height: 0;
                  background-color: transparent;
                  transition: none;
                }
              }
              .nameAndPrice {
                @include flexCol(4px);
                display: flex;
                align-items: flex-start;
                color: $NEUTRAL-70;
                transition: 0.3s;
                h3 {
                  font-size: 14px;
                  font-weight: 700;
                  overflow: hidden;
                  display: -webkit-box;
                  -webkit-box-orient: vertical;
                  -moz-box-orient: vertical;
                  display: -moz-box;
                  line-clamp: 2;
                  -webkit-line-clamp: 2;
                }
                > span {
                  font-size: 14px;
                  font-weight: 500;
                  color: $NEUTRAL-60;
                }
              }
              .nameAndPrice[is-active="true"] {
                h3 {
                  color: $NEUTRAL-10;
                }
              }
            }
            .footDescription[is-active="true"] {
              background: rgba(0, 0, 0, 0.65);
            }
          }
        }
        .rightArea {
          padding-top: 0;
          padding-inline: 16px;
          order: 1;
          width: 100%;
          .topDecription {
            row-gap: 1rem;
            > div {
              @include flexCol(8px);
              h1 {
                font-size: 24px;
                line-height: 28px;
              }
              > p {
                font-size: 14px;
                line-height: 20px;
                -webkit-line-clamp: 6;
                line-clamp: 6;
                height: 120px;
              }
              > span {
                font-size: 14px;
              }
            }

            > .button {
              display: none;
            }
          }
          .wrapperFoot {
            display: none;
          }
        }
      }
    }
  }
}
