@import "../../../themes/color";
@import "../../../themes/global";
.bodyNavbarBox {
  .navbarBox {
    .backdrop {
      position: fixed;
      left: 0;
      right: 0;
      bottom: 0;
      top: 86px;
      height: 100vh;
      width: 100vw;
      background-color: rgba(0, 0, 0, 0.6);
    }
    .wrapperMenuMotor {
      left: 0;
      width: 100%;
      position: absolute;
      top: 86px;
      background-color: $NEUTRAL-10;
      height: 0;
      overflow: hidden;
    }
    .wrapperMenuMotor[is-open-menu="true"] {
      transition: 0.5s;
      height: 560px;
      // height: auto;
      padding: 32px 32px 32px 40px;
      @include flexCol(32px);
      align-items: center;
      .tabsLogoMotor {
        display: flex;
        gap: 8px;
        .logoIcon {
          width: 179px;
          height: 94px;
          @include flexCol(4px);
          align-items: center;
          justify-content: center;
          position: relative;
          cursor: pointer;
          &::before {
            content: "";
            position: absolute;
            top: 0;
            right: 0;
            bottom: 0;
            left: 0;
            background-color: $NEUTRAL-30;
            border-radius: 8px;
            border: 1px solid $PRIMARY;
            transform: skew(-15deg);
            z-index: 1;
            opacity: 0;
            transition: opacity 0.5s, transform 1s;
          }
          &:hover::before {
            opacity: 1;
          }
          > img {
            width: 85px;
            height: 54px;
            flex-shrink: 0;
            transform: none;
            z-index: 2;
          }
          > span {
            font-family: Roboto;
            color: $PRIMARY;
            font-size: 16px;
            font-weight: 700;
            line-height: 24px;
            transform: none;
            z-index: 2;
          }
        }
        .logoIcon[is-active="true"] {
          &::before {
            background-color: $PRIMARY;
            opacity: 1;
            box-shadow: 0px 0px 20px 0px rgba(0, 0, 0, 0.2);
          }
          > span {
            color: $NEUTRAL-10;
          }
        }
      }
      .carouselProducts {
        display: flex;
        gap: 1rem;
        max-width: 1120px;
        overflow-x: hidden;
        .card {
          cursor: pointer;
          padding: 1rem;
          border-radius: 9px;
          display: flex;
          flex-direction: column;
          position: relative;
          background: $NEUTRAL-10;
          min-width: 268px;
          width: 100%;
          height: 282px;
          height: auto;
          border: 1px solid #ebebeb;
          padding: 0 18px 18px 18px;
          .otr {
            font-size: 10px;
            line-height: 12px;
            color: $NEUTRAL-80;
            min-width: 119px;
          }
          .header {
            > div {
              .title {
                color: $NEUTRAL-100;
                font-weight: 700;
              }
              span:nth-child(2) {
                color: $NEUTRAL-50;
                font-size: 14px;
              }
            }
            .discount {
              position: absolute;
              top: 10px;
              right: 10px;
              background-color: $DANGER;
              display: flex;
              justify-content: center;
              align-items: center;
              border-radius: 50%;
              flex-shrink: 0;
              width: 50px;
              height: 50px;
              > span {
                color: $NEUTRAL-10;
                font-size: 14px;
                font-weight: 700;
                position: relative;
                left: 0;
              }
            }
          }
          &:hover {
            .image {
              img {
                transform: scale(1.2);
              }
            }
          }
          .image {
            padding-block: 38px;
            img {
              transition: 0.5s;
              width: 162.151px;
              height: 139.63px;
              flex-shrink: 0;
              object-fit: contain;
            }
          }
          .offers {
            gap: 10px;
            .pricing {
              row-gap: 2px;
              span:nth-child(1) {
                color: $DANGER;
                font-size: 20px;
                font-weight: 700;
                line-height: 24px;
              }
              span:nth-child(2) {
                color: $NEUTRAL-50;
                font-size: 14px;
                font-weight: 500;
                line-height: 20px;
                text-decoration-line: line-through;
              }
            }
          }
          .button {
            padding: 8px 12px;
            border-radius: 8px;
            outline: 1px solid $NEUTRAL-40;
            border: 1px solid transparent;
            background-color: $NEUTRAL-10;
            display: flex;
            justify-content: center;
            cursor: pointer;
            > span {
              color: $NEUTRAL-100;
              text-align: center;
              font-size: 14px;
              font-weight: 700;
              line-height: 20px;
            }
            &:hover {
              border: 1px solid $NEUTRAL-40;
            }
          }
          .descContent {
            @include flexCol(6px);
            > h3 {
              font-size: 12px;
              display: -webkit-box;
              -webkit-box-orient: vertical;
              line-clamp: 1;
              -webkit-line-clamp: 1;
              overflow: hidden;
            }
            > .wrappPricing {
              display: flex;
              align-items: center;
              justify-content: space-between;
              // gap: 17.5px;
              > div:nth-child(1) {
                @include flexCol(3px);
                .otr {
                  color: $NEUTRAL-60;
                  font-family: Roboto;
                  font-size: 7.507px;
                  font-style: normal;
                  font-weight: 400;
                  line-height: 9.008px;
                }
                .price {
                  font-size: 15px;
                  color: $PRIMARY;
                  font-weight: 700;
                  font-family: Roboto;
                }
              }
              .colors {
                display: flex;
                gap: 2px;
                align-items: center;
                .chooseColor {
                  display: flex;
                  .colorBullet {
                    width: 18px;
                    height: 18px;
                    transform: skewX(-30deg);
                    perspective: 20px;
                    border-top-left-radius: 2px;
                    border-top-right-radius: 6px;
                    border-bottom-left-radius: 6px;
                    border-bottom-right-radius: 2px;
                  }
                }
                > span {
                  margin-left: 4px;
                  font-weight: 700;
                  color: $PRIMARY;
                  font-size: 12px;
                }
              }
            }
          }
        }
        .wrapperChevron {
          width: calc(1120px + 48px + 100px);
          height: 50px;
          left: 50.3%;
          top: 53%;
          transform: translate(-50%, -50%);
          position: absolute;
          display: flex;
          justify-content: space-between;
          .chevronLeft,
          .chevronRight {
            background-color: $PRIMARY-FOCUSED;
            display: flex;
            align-items: center;
            justify-content: center;
            border-radius: 50%;
            width: 50px;
            padding: 13px;
            .icon {
              color: $NEUTRAL-10;
              width: 24px;
              height: 24px;
              flex-shrink: 0;
            }
          }
          .chevronLeft[is-disabled="true"],
          .chevronRight[is-disabled="true"] {
            cursor: not-allowed;
          }
          .chevronLeft[is-disabled="false"],
          .chevronRight[is-disabled="false"] {
            cursor: pointer;
            background-color: $PRIMARY;
            &:hover {
              background-color: $PRIMARY-HOVER;
            }
          }
        }
      }
      .buttonMenu {
        padding: 8px 12px;
        border-radius: 8px;
        outline: 1px solid $NEUTRAL-40;
        border: 1px solid transparent;
        background-color: $NEUTRAL-10;
        display: flex;
        justify-content: center;
        cursor: pointer;
        > span {
          color: $NEUTRAL-100;
          text-align: center;
          font-size: 14px;
          font-weight: 700;
          line-height: 20px;
        }
        &:hover {
          border: 1px solid $NEUTRAL-40;
        }
      }
    }
  }
}
.bodyNavbarBox {
  display: flex;
  flex-direction: column;
  // padding: 16px 48px;
  padding: 8px calc(104px - 16px);
  height: 86px;
  gap: 16px;
  top: 0;
  position: sticky;
  z-index: 5;
  background: rgba(255, 255, 255, 0.7);
  backdrop-filter: blur(8px);
  width: 100%;
  justify-content: center;
  align-items: center;
  &.darkBackground {
    background: var(--neutral-90, #212121);
    backdrop-filter: blur(8px);
  }
  .navbarBox {
    display: flex;
    align-items: center;
    justify-content: space-between;
    max-width: 1280px;
    width: 100%;
    padding-inline: 1rem;
  }
  .leftLogo {
    display: flex;
    align-items: center;
    gap: 8px;
    .logo {
      width: 36px;
      height: 36px;
    }
    .logoText {
      width: 140px;
      height: 20px;
    }
  }

  .right {
    display: flex;
    align-items: center;
    gap: 44px;
    .routeRight {
      display: flex;
      align-items: center;
      gap: 24px;
      .routeUser {
        display: flex;
        align-items: center;
        gap: 8px;
        cursor: pointer;
      }
      .routeRegister {
        cursor: pointer;
        display: flex;
        align-items: center;
        justify-content: center;
        padding: 5px 16px;
        border-radius: 6px;
        background: var(--primary-main, #357abb);
        color: var(--neutral-10, #fff);
        text-align: center;
        font-size: calc(14px + (14 - 14) * ((100vw - 280px) / (1600 - 280)));
        font-style: normal;
        font-weight: 700;
        line-height: 24px; /* 150% */
      }
      .route {
        display: flex;
        align-items: center;
        cursor: pointer;

        .titleRoute {
          text-decoration: none;
          color: var(--neutral-100, #212121);
          text-align: center;
          font-size: calc(14px + (16 - 14) * ((100vw - 280px) / (1600 - 280)));
          font-style: normal;
          font-weight: 700;
          line-height: 24px; /* 150% */
          &.titleLight {
            color: #fff;
          }
        }
        .titleRouteRed {
          text-decoration: none;
          color: var(--neutral-100, #d1001c);
          text-align: center;
          font-size: calc(14px + (16 - 14) * ((100vw - 280px) / (1600 - 280)));
          font-style: normal;
          font-weight: 700;
          line-height: 24px; /* 150% */
          &.titleLight {
            color: #fff;
          }
        }
      }
    }
    .routeLeft {
      display: flex;
      align-items: center;
      gap: 24px;
      .route {
        display: flex;
        align-items: center;
        gap: 8px;
        cursor: pointer;
        position: relative;
        text-decoration: none;
        .titleRoute {
          color: var(--neutral-100, #212121);
          text-align: center;
          font-size: calc(14px + (16 - 14) * ((100vw - 280px) / (1600 - 280)));
          font-style: normal;
          font-weight: 700;
          line-height: 24px; /* 150% */
          &.titleLight {
            color: #fff;
          }
        }
        .titleRouteRed {
          color: var(--neutral-100, #d1001c);
          text-align: center;
          font-size: calc(14px + (16 - 14) * ((100vw - 280px) / (1600 - 280)));
          font-style: normal;
          font-weight: 700;
          line-height: 24px; /* 150% */
          &.titleLight {
            color: #fff;
          }
        }
      }
    }
  }
}
.countCart {
  position: absolute;
  padding: 7px 4px;
  background: #d1001c;
  color: #fff;
  // z-index: 1;
  font-size: 10px;
  border-radius: 50%;
  width: 25px;
  height: 25px;
  font-weight: 800;
  margin: -25px 0 0 10px;
  text-align: center;
}

.boxDropdown {
  position: absolute;
  border-radius: 8px;
  background: var(--neutral-10, #fff);
  padding: 16px 16px;
  top: 80%;
  // right: 425px;
  display: flex;
  flex-direction: column;
  gap: 24px;
  box-shadow: 0px 0px 12px 0px rgba(0, 0, 0, 0.1);
  .title {
    text-decoration: none;
    color: var(--neutral-100, #212121);
    font-size: calc(14px + (16 - 14) * ((100vw - 280px) / (1600 - 280)));
    font-style: normal;
    font-weight: 400;
    line-height: 24px; /* 150% */
  }
}

.boxUserDropdown {
  position: absolute;
  top: 80%;
  right: 46px;
  display: flex;
  flex-direction: column;
  box-shadow: 0px 0px 12px 0px rgba(0, 0, 0, 0.1);
  border-radius: 8px;
  background: var(--neutral-10, #fff);
  .logOutUser {
    cursor: pointer;
    padding: 16px;
    .text {
      color: var(--neutral-100, #212121);
      font-size: 16px;
      font-style: normal;
      font-weight: 400;
      line-height: 24px; /* 150% */
    }
  }
  .menuUserBox {
    display: flex;
    flex-direction: column;
    padding: 12px 16px;
    gap: 12px;
    border-bottom: 1px solid #eaeaea;
    .text {
      text-decoration: none;
      cursor: pointer;
      color: var(--neutral-100, #212121);
      font-size: 16px;
      font-style: normal;
      font-weight: 400;
      line-height: 24px; /* 150% */
    }
  }
  .userBox {
    display: flex;
    align-items: center;
    padding: 16px;
    border-bottom: 1px solid #eaeaea;
    gap: 12px;
    .nameBox {
      display: flex;
      flex-direction: column;
      gap: 4px;
      .name {
        color: #000;
        font-size: 16px;
        font-style: normal;
        font-weight: 700;
        line-height: 24px; /* 150% */
      }
      .email {
        color: #000;
        font-size: 12px;
        font-style: normal;
        font-weight: 400;
        line-height: 14px; /* 116.667% */
      }
    }
  }
}

.responsiveBox {
  display: none;
}

.rigtResponsive {
  display: none;
}

.boxUserDropdownResponsive {
  display: none;
}

@media (max-width: 1024px) {
  .rigtResponsive {
    display: flex;
    align-items: center;
    gap: 24px;
    cursor: pointer;
    .routeUser {
      display: flex;
      align-items: center;
      gap: 8px;
      cursor: pointer;
    }
    .countCart {
      margin: -40px 0 0 10px;
    }
  }

  .boxUserDropdown {
    display: none;
  }

  .bodyUserDropdown {
    display: flex;
    flex-direction: column;
    // position: fixed;
    position: absolute;
    top: 100%;
    left: 0;
    right: 0;
    z-index: 1;
    width: 100%;
    height: 100vh;
    .boxUserDropdownResponsive {
      display: flex;
      flex-direction: column;

      background: rgba(255, 255, 255, 0.9);
      backdrop-filter: blur(8px);
      &.darkBackground {
        background: var(--neutral-90, #2b2b2b);
        backdrop-filter: blur(8px);
      }
      .logOutUser {
        padding: 16px 48px;
        cursor: pointer;
        .text {
          color: var(--neutral-100, #212121);
          font-size: 16px 0px;
          font-style: normal;
          font-weight: 400;
          line-height: 24px; /* 150% */
          &.nameLight {
            color: #fff;
          }
        }
      }
      .menuUserBox {
        display: flex;
        flex-direction: column;
        padding: 12px 48px;
        gap: 12px;
        border-bottom: 1px solid #eaeaea;
        &.menuUserBoxLight {
          border-bottom: 1px solid #474646;
        }
        .text {
          text-decoration: none;
          cursor: pointer;
          color: var(--neutral-100, #212121);
          font-size: 16px;
          font-style: normal;
          font-weight: 400;
          line-height: 24px; /* 150% */
          &.nameLight {
            color: #fff;
          }
        }
      }
      .userBox {
        display: flex;
        align-items: center;
        padding: 16px 48px;
        border-bottom: 1px solid #eaeaea;
        gap: 12px;
        &.userBoxLight {
          border-bottom: 1px solid #474646;
        }
        .nameBox {
          display: flex;
          flex-direction: column;
          gap: 4px;
          .name {
            color: #000;
            font-size: 16px;
            font-style: normal;
            font-weight: 700;
            line-height: 24px; /* 150% */
            &.nameLight {
              color: #fff;
            }
          }
          .email {
            color: #000;
            font-size: 12px;
            font-style: normal;
            font-weight: 400;
            line-height: 14px; /* 116.667% */
            &.nameLight {
              color: #fff;
            }
          }
        }
      }
    }
    .null {
      display: flex;
      height: 100%;
    }
  }

  .responsiveBox {
    display: flex;

    cursor: pointer;
  }

  .bodyNavbarBox {
    padding-left: 50px;
    padding-right: 50px;
    .navbarBox {
      .leftLogo {
        display: none;
      }

      .right {
        display: none;
      }
    }
  }
}

.bodyResponsive {
  position: fixed;
  display: flex;
  left: 0;
  top: 0;
  z-index: 5;
  // background: #FFF;
  height: 100vh;
  width: 100%;

  .closeBox {
    width: 100%;
    // width: 50%;
  }
  .boxResponsive {
    display: flex;
    flex-direction: column;
    gap: 44px;
    padding: 32px 16px;
    background: #fff;
    width: 500px;
    height: 100%;
    .buttomBox {
      display: flex;
      flex-direction: column;
      gap: 44px;
      .routesButtom {
        display: flex;
        flex-direction: column;

        gap: 10px;
        padding-bottom: 32px;
      }
      .routesTop {
        display: flex;
        flex-direction: column;
        gap: 16px;
      }
      .routeRegister {
        display: flex;
        align-items: center;
        justify-content: center;
        padding: 6px 16px;
        border-radius: 8px;
        background: var(--primary-main, #357abb);
        color: var(--neutral-10, #fff);
        text-align: center;
        font-size: calc(14px + (16 - 14) * ((100vw - 280px) / (1600 - 280)));
        font-style: normal;
        font-weight: 700;
        line-height: 24px; /* 150% */
        width: 100%;
        cursor: pointer;
      }
      .route {
        text-decoration: none;
        display: flex;
        align-items: center;
        gap: 8px;
        cursor: pointer;
        justify-content: space-between;
        .titleRoute {
          color: var(--neutral-100, #212121);
          text-align: center;
          font-size: calc(14px + (16 - 14) * ((100vw - 280px) / (1600 - 280)));
          font-style: normal;
          font-weight: 700;
          line-height: 24px; /* 150% */
        }
        .titleRouteRed {
          color: var(--neutral-100, #d1001c);
          text-align: center;
          font-size: calc(14px + (16 - 14) * ((100vw - 280px) / (1600 - 280)));
          font-style: normal;
          font-weight: 700;
          line-height: 24px; /* 150% */
        }
      }
    }
    .topBox {
      display: flex;
      align-items: center;
      justify-content: space-between;
      .close {
        .xMark {
          width: 20px;
          cursor: pointer;
        }
      }
      .logoBox {
        display: flex;
        align-items: center;
        gap: 8px;
        .imageLogo {
          // width: 10%;
          width: 30px;
          height: 30px;
        }
        .textLogo {
          // width: 40%;
          width: 100px;
          height: 10px;
        }
      }
    }
  }

  .listProduct {
    display: flex;
    flex-direction: column;
    gap: 16px;
    padding-left: 16px;
    .list {
      text-decoration: none;
      color: var(--neutral-100, #212121);
      // text-align: center;
      font-size: calc(14px + (16 - 14) * ((100vw - 280px) / (1600 - 280)));
      font-style: normal;
      font-weight: 700;
      line-height: 24px; /* 150% */
    }
  }
}

@media (max-width: 600px) {
  .bodyUserDropdown {
    .boxUserDropdownResponsive {
      .logOutUser {
        cursor: pointer;
        padding: 16px 16px;
      }
      .menuUserBox {
        padding: 12px 16px;
      }
      .userBox {
        padding: 16px 16px;
      }
    }
  }

  .bodyNavbarBox {
    padding-top: 12px;
    height: 64px;
    padding-left: 16px;
    padding-right: 16px;
    padding-bottom: 12px;
    .navbarBox {
      padding: 0;
    }
  }
  .bodyResponsive {
    .boxResponsive {
      .topBox {
        .logoBox {
          .imageLogo {
            // width: 10%;
            width: 30px;
            height: 30px;
          }
          .textLogo {
            // width: 40%;
            width: 65px;
            height: 10px;
          }
        }
      }
    }
  }
  .forChildren {
    padding-inline: 1rem !important;
  }
}
.forChildren {
  padding-inline: 48px;
}
