@use "themes/color";

.magnifier {
  position: relative;
  width: 100%;
  height: 100%;
  margin: 44px 111px 25px 111px;
}

.magnifierImage {
  // width: 100%;
  // height: 100%;
  width: 565px;
  height: 488px;
  flex-shrink: 0;
  transition: 0.2s;
  object-fit: contain;
}

.magnifiedImage {
  width: 200px;
  height: 200px;
  border-radius: 100px;
  border: 2px solid color.$NEUTRAL-10;
  background-color: color.$NEUTRAL-10;
  position: absolute;
  pointer-events: none;
  background-repeat: no-repeat;
}
@media (max-width: 768px) {
  .magnifierImage {
    width: 277px;
    height: 239px;
  }
}
