$NEUTRAL-100: #212121;
$NEUTRAL-90: #424242;
$NEUTRAL-80: #575757;
$NEUTRAL-70: #6D6D6D;
$NEUTRAL-60: #9E9E9E;
$NEUTRAL-50: #C7C7C7;
$NEUTRAL-40: #DDDDDD;
$NEUTRAL-30: #EAEAEA;
$NEUTRAL-20: #F5F5F5;
$NEUTRAL-10: #FFFFFF;

$PRIMARY: #357ABB;
$PRIMARY-PRESSED: #23517D;
$PRIMARY-HOVER: #2C669C;
$PRIMARY-BORDER: #9ABCDD;
$PRIMARY-FOCUSED: #BCD3E8;
$PRIMARY-SURFACE: #D7E4F1;

$DANGER: #D1001C;
$DANGER-BORDER: #FFAAB5;
$DANGER-SURFACE: #FFF2F4;

$WARNING: #E9B62F;
$WARNING-SURFACE: #FFF9E9;

$SUCCESS: #65D072;
$SUCCESS-BORDER: #B2E7B8;
$SUCCESS-SURFACE: #EFFFF1;
