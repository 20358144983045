@import "../../../themes/color";

.wrapperEmpty {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  max-height: calc(744px - 68px - 6px);
  // height: 100vh;
  justify-content: center;
  padding-inline: 12px;
  > img[alt="empty-images"] {
    width: 234.438px;
    height: 179.291px;
  }
  .wrapDescripiton {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    align-items: center;
    > h1 {
      color: $NEUTRAL-100;
      text-align: center;
      font-size: 24px;
      font-weight: 700;
      line-height: 28px;
    }
    > span {
      color: $NEUTRAL-60;
      text-align: center;
      font-size: 14px;
      line-height: 20px;
      max-width: 358px;
      width: 100%;
    }
  }
}
