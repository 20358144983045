@import "../../../../themes/color";
.stepBox {
  display: flex;
  flex-direction: column;
  gap: 24px;
  .stepTitle {
    display: flex;
    align-items: center;
    justify-content: center;
    .title {
      color: $NEUTRAL-100;
      text-align: center;
      font-size: 24px;
      font-weight: 700;
      line-height: 28px;
    }
  }

  .cardStep {
    display: flex;
    gap: 24px;
    // max-width: 1320px;
    align-self: center;
    .card {
      display: flex;
      flex-direction: column;
      padding: 32px;
      gap: 16px;
      border-radius: 16px;
      border: 3px solid $NEUTRAL-20;
      background: $NEUTRAL-10;
      // max-width: calc(1280px / 3);
      width: 432px;
      // width: 100%;
      .topCardStep {
        display: flex;
        align-items: center;
        justify-content: space-between;
        .number {
          display: flex;
          justify-content: center;
          align-items: center;
          border-radius: 50%;
          border: 1px solid $PRIMARY;
          width: 53px;
          height: 53px;
          flex-shrink: 0;
          .numb {
            font-size: 36px;
            font-weight: 700;
            line-height: 36px;
            color: $PRIMARY;
          }
        }
        .icon {
          &:nth-child(1) {
            width: 45.429px;
            height: 53.111px;
          }
          &:nth-child(2),
          &:nth-child(3) {
            width: 53px;
            height: 53px;
          }
        }
      }

      .bottomCardStep {
        display: flex;
        flex-direction: column;
        gap: 4px;
        .ketText {
          color: $NEUTRAL-100;
          font-size: 1rem;
          font-weight: 400;
          line-height: 24px;
        }
        .textStep {
          color: $PRIMARY;
          font-size: 24px;
          font-weight: 700;
          line-height: 28px;
        }
      }
    }
  }
}
@media (max-width: 1024px) {
  .stepBox {
    .cardStep {
      .card {
        padding: 1rem;
        width: 100%;
        .topCardStep {
          .number {
            width: 43px;
            height: 43px;
          }
          .icon {
            &:nth-child(1) {
              width: 40.429px;
              height: 43.111px;
            }
            &:nth-child(2),
            &:nth-child(3) {
              width: 43px;
              height: 43px;
            }
          }
        }
      }
    }
  }
}
@media (max-width: 768px) {
  .stepBox {
    .stepTitle {
      .title {
        color: $NEUTRAL-100;
        text-align: center;
        font-size: 24px;
        font-weight: 700;
        line-height: 28px;
      }
    }
    .cardStep {
      flex-direction: column;
      row-gap: 12px;
      width: 100%;
      align-items: center;
      .card {
        padding: 1rem;
        gap: 12px;
        width: 100%;
        .bottomCardStep {
          .textStep {
            font-size: 1rem;
          }
          .ketText {
            line-height: normal;
            font-size: 14px;
          }
        }
        .topCardStep {
          .number {
            width: 36px;
            height: 36px;
            flex-shrink: 0;
            padding: 0;
            .numb {
              font-size: 16px;
              font-weight: 700;
            }
          }
          .icon {
            &:nth-child(1) {
              width: 30.428px;
              height: 35.573px;
              flex-shrink: 0;
            }
            &:nth-child(1),
            &:nth-child(2) {
              width: 36px;
              height: 36px;
            }
          }
        }
      }
    }
  }
}
