@import "../../../themes/global";
@import "../../../themes/color";

.ButtonWhatsApp {
  padding: 8px 8px 8px 12px;
  border-radius: 40px;
  @include flexCenter;
  gap: 8px;
  background-color: $NEUTRAL-10;
  z-index: 9999;
  width: fit-content;
  box-shadow: 0px 6px 24px 1px rgba(78, 78, 78, 0.25);
  cursor: pointer;
  position: fixed;
  right: 1rem;
  bottom: 1rem;
  > span {
    font-size: 1rem;
    font-weight: 700;
    color: $PRIMARY;
  }
  .wrapImg {
    width: 36px;
    aspect-ratio: 1;
    border-radius: 50%;
    background-color: $PRIMARY;
    @include flexCenter;
    > img[alt="whatsapp-logo"] {
      width: 24px;
      height: 24px;
      flex-shrink: 0;
    }
  }
}
@media (max-width: 768px) {
  .ButtonWhatsApp {
    padding: 4px 4px 4px 8px;
    > span {
      font-size: 14px;
    }
    .wrapImg {
      width: 30px;
      > img[alt="whatsapp-logo"] {
        width: 18px;
        height: 18px;
      }
    }
  }
  .ButtonWhatsApp[mobile-list-products="true"] {
    bottom: 5rem;
  }
}
