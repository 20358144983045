.bodyShowRegister {
  display: flex;
  flex-direction: column;
  gap: 40px;
  padding: 32px;
}

.logoPermata {
  display: flex;
  align-items: center;
  justify-content: space-between;
  .imagesBox {
    display: flex;
    align-items: center;
    gap: 8px;
    width: 50%;
    .imageLogo {
      width: 36px;
      height: 36px;
    }
    .logoText {
      width: 130px;
      height: 18px;
    }
  }

  .iconXmark {
    cursor: pointer;
  }
}

.inputLoginContainer {
  display: flex;
  flex-direction: column;
  .descBox {
    display: flex;
    flex-direction: column;
    gap: 8px;
    .login {
      color: var(--neutral-100, #212121);
      font-size: calc(24px + (48 - 24) * ((100vw - 280px) / (1600 - 280)));
      font-style: normal;
      font-weight: 700;
      line-height: 64px; /* 133.333% */
    }
    .desc {
      color: var(--neutral-100, #212121);
      font-size: calc(14px + (16 - 14) * ((100vw - 280px) / (1600 - 280)));
      font-style: normal;
      font-weight: 400;
      line-height: 24px; /* 150% */
    }
  }

  .inputBox {
    padding-top: 24px;
    padding-bottom: 32px;
    display: flex;
    flex-direction: column;
    gap: 16px;
    input {
      border-radius: 8px;
      border: 1px solid var(--neutral-40, #ddd);
      opacity: 0.8;
      background: var(--neutral-10, #fff);
      padding: 12px 16px;
      width: 100%;
    }
    input::placeholder {
      color: var(--neutral-70, #575757);
      font-size: 14px;
      font-style: normal;
      font-weight: 400;
      line-height: 20px; /* 142.857% */
    }
    .buttonLogin {
      cursor: pointer;
      display: flex;
      padding: 12px 16px;
      justify-content: center;
      align-items: center;
      border-radius: 8px;
      background: var(--primary-main, #357abb);
      color: var(--neutral-10, #fff);
      text-align: center;
      font-size: calc(14px + (16 - 14) * ((100vw - 280px) / (1600 - 280)));
      font-style: normal;
      font-weight: 700;
      line-height: 24px; /* 150% */
      margin-top: 20px;
    }
  }

  .lineBox {
    display: flex;
    align-items: center;
    // justify-content: space-between;
    gap: 8px;
    .lineLeft {
      width: 50%;
      border: 1px solid #c7c7c7;
    }
    .or {
      color: var(--neutral-80, #424242);
      font-size: 14px;
      font-style: normal;
      font-weight: 400;
      line-height: 20px; /* 142.857% */
    }
  }

  .googleLoginBox {
    display: flex;
    flex-direction: column;
    gap: 24px;
    padding-top: 32px;
    .descGoogleLogin {
      color: var(--neutral-100, #212121);
      font-size: calc(14px + (16 - 14) * ((100vw - 280px) / (1600 - 280)));
      font-style: normal;
      font-weight: 400;
      line-height: 24px; /* 150% */
    }

    .buttonGoggleLogin {
      display: flex;
      align-items: center;
      justify-content: center;
      gap: 8px;
      padding: 12px 16px;
      border-radius: 8px;
      border: 1px solid var(--neutral-100, #212121);
      opacity: 0.8;
      background: var(--neutral-10, #fff);
      cursor: pointer;
      .title {
        color: var(--neutral-100, #212121);
        text-align: center;
        font-size: 14px;
        font-style: normal;
        font-weight: 700;
        line-height: 20px; /* 142.857% */
      }
    }
  }
}

.routeLogin {
  display: flex;
  align-items: center;
  gap: 4px;
  .textBlack {
    color: var(--neutral-100, #212121);

    font-size: calc(14px + (16 - 14) * ((100vw - 280px) / (1600 - 280)));
    font-style: normal;
    font-weight: 400;
    line-height: 24px; /* 150% */
  }
  .textBlue {
    cursor: pointer;
    color: var(--primary-main, #357abb);
    font-size: calc(14px + (16 - 14) * ((100vw - 280px) / (1600 - 280)));
    font-style: normal;
    font-weight: 400;
    line-height: 24px;
  }
}

@media (max-width: 540px) {
  .bodyShowRegister {
    padding: 32px 16px;
  }
}
