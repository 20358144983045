@import "../../../themes/color";

.wrapperLoading {
  background-color: $NEUTRAL-10;
  padding: 2rem;
  display: flex;
  align-items: center;
  justify-content: center;
  // height: 100vh;
  .loadSpinner {
    border: 8px solid $PRIMARY_SURFACE;
    border-left-color: $PRIMARY;
    border-radius: 50%;
    width: 60px;
    height: 60px;
    animation: spin 0.8s infinite linear;
  }

  @keyframes spin {
    to {
      transform: rotate(360deg);
    }
  }
}
