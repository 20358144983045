$BackgroundLandingPage: url("../../../assets/img/home-wallpaper.png");
@import "../../../themes/color";
.topBody {
  display: flex;
  flex-direction: column;
  // row-gap: -200px !important;
  // padding-left: 48px;
  // padding-right: 48px;
  padding-block: 0;
  margin: 0;
  width: 100%;
  .wrapperTop {
    @media (max-width: 600px) {
      padding-bottom: calc(672px) !important;
    }
    @media (max-width: 1024px) {
      padding-bottom: calc(524px);
    }
  }
  .firstTop {
    background: ($BackgroundLandingPage) no-repeat;
    background-position-y: left;
    background-position: center;
    background-size: 100%;
    width: max-content;
    border-radius: 24px;
    display: flex;
    justify-content: center;
    align-self: center;
    margin-top: 1rem;
    @media (max-width: 768px) {
      margin-top: 0;
      padding: 1rem;
      border-radius: 8px;
    }
    padding-block: 40px;
    .homeLandingPage {
      display: flex;
      align-items: center;
      padding: 0 70px 0 85px;
      background-size: cover;
      background-repeat: no-repeat;
      max-width: calc(1280px + 4rem);
      width: 100%;
      height: 100%;
      border-radius: 24px;
      .textLandingPage {
        display: flex;
        flex-direction: column;
        gap: 24px;
        width: 100%;
        .text {
          color: $NEUTRAL-100;
          font-size: 48px;
          font-style: normal;
          font-weight: 700;
          width: fit-content;
        }
        .buttonSection {
          cursor: pointer;
          display: flex;
          width: fit-content;
          padding: 12px 16px;
          justify-content: center;
          border-radius: 8px;
          background: $PRIMARY;
          color: $NEUTRAL-10;
          text-align: center;
          font-size: 16px;
          font-style: normal;
          font-weight: 700;
          line-height: 24px;
          text-decoration: none;
          @media (max-width: 768px) {
            padding: 8px 12px;
            font-size: 14px;
          }
        }
        .buttonSection:hover {
          background: $PRIMARY-HOVER;
        }
      }

      .imageLandingPage {
        width: 100%;
        display: flex;
        justify-content: flex-end;
        align-self: flex-end;
        justify-self: flex-end;
        position: relative;
        right: -32.5px;
        img {
          max-width: 524px;
          max-height: 559px;
          width: 100%;
          height: 100%;
        }
      }
    }
  }
  .carouselImageBox {
    display: flex;
    justify-content: center;
  }
}
.wrapperBenefit {
  display: flex;
  justify-content: center;
}
.boxBenefit {
  display: flex;
  gap: 24px;
  padding: 48px 0px 48px 0px;
  justify-content: center;
  // max-width: 1280px;
  width: 1340px;
  // align-self: center;
  .box {
    display: flex;
    flex-direction: column;
    gap: 8px;
    // max-width: 432px;
    width: 100%;
    align-items: center;
    justify-content: center;
    padding: 32px;
    border-radius: 16px;
    background: $NEUTRAL-20;
    .title {
      color: $NEUTRAL-100;
      text-align: center;
      font-size: 24px;
      font-weight: 500;
      line-height: 24px;
    }
    .ket {
      color: $NEUTRAL-100;
      text-align: center;
      font-size: 14px;
      font-weight: 400;
      line-height: 16px;
    }
  }
}

.boxStep {
  padding: 64px 0px;
  @media (max-width: 768px) {
    padding: 27px 0px;
  }
}

.advantageBox {
  padding: 64px 0px;
}

.testiCard {
  padding: 64px 0px 128px 0px;
}

.promoCarouselBox {
  padding: 64px 0px 12px 0px;
}

.imageLandingPageResponsive {
  display: none;
}

@media (max-width: 768px) {
  .topBody {
    .wrapperBenefit,
    .carouselImageBox,
    .boxStep,
    .advantageBox {
      padding: 1rem;
    }
  }
  .topBody {
    // padding: 1rem;
    padding: 0 !important;
    .firstTop {
      border-radius: 16px;
      width: 100%;
      background: ($BackgroundLandingPage) no-repeat;
      background-size: cover;
      background-position-y: left;
      background-position: center;
      .homeLandingPage {
        width: fit-content;
        display: flex;
        flex-direction: column;
        align-items: center;
        row-gap: 32px;
        .textLandingPage {
          order: 2;
          .text {
            font-size: 24px;
            max-width: 341px;
          }
          .buttonSection {
            width: 100%;
          }
        }
        // @media (max-width: 600px) {
        padding: 0 !important;
        .imageLandingPage {
          display: flex;
          justify-content: center;
          width: 100%;
          order: 1;
          position: static;
          > img {
            width: 239.317px;
            height: 255.302px;
          }
        }
        // }
      }
    }
  }
  .promoCarouselBox {
    padding-top: 32px;
    padding-bottom: 20px;
  }

  .advantageBox {
    padding-top: 20px;
    padding-bottom: 20px;
  }

  .testiCard {
    padding-bottom: 64px;
  }

  .boxBenefit {
    padding-top: 16px;
    flex-direction: column;
    gap: 16px;
    .box {
      padding: 24px 32px;
    }
    .title {
      font-size: 16px !important;
    }
  }
}

@media (max-width: 1024px) {
  .promoCarouselBox {
    padding-block: 16px 24px;
  }
  .topBody {
    padding: 0px 16px;
    .firstTop {
      width: 100%;
    }
    .boxBenefit {
      gap: 12px;
      .box {
        gap: 8px;
        padding: 24px;
        align-items: flex-start;
        .title {
          font-size: 18px;
          font-weight: 700;
          line-height: normal;
          padding: 0;
          margin: 0;
        }
        .ket {
          font-size: 14px;
          text-align: left;
        }
      }
    }
    .textLandingPage {
      .text {
        font-size: 24px !important;
      }
    }
  }
}
