@import "../../../../themes/color";
.boxAdvantage {
  display: flex;
  flex-wrap: wrap;
  width: 100%;
  justify-content: center;
  gap: 4px;
  .titleAdvantage {
    color: $NEUTRAL-10;
    text-align: center;
    font-size: 48px;
    font-weight: 700;
    z-index: 1;
  }

  .ketAdvantage {
    width: 300px;
    color: $NEUTRAL-10;
    text-align: center;
    font-size: 14px;
    font-weight: 400;
    line-height: 16px;
    z-index: 1;
  }
  @mixin background-image-advantage($image-url) {
    background-image: url($image-url);
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
  .boxFirst {
    @include background-image-advantage(
      "../../../../assets/img/yamaha-property.png"
    );
    border-top-left-radius: 12px;
  }
  .boxSecond {
    @include background-image-advantage(
      "../../../../assets/img/trusted-image.png"
    );
    border-top-right-radius: 12px;
  }

  .boxThird {
    @include background-image-advantage(
      "../../../../assets/img/calculator-image.png"
    );
    border-bottom-left-radius: 12px;
  }

  .boxFourth {
    @include background-image-advantage(
      "../../../../assets/img/fast-imagine-image.png"
    );
    border-bottom-right-radius: 12px;
  }
}
.boxFirst,
.boxSecond,
.boxThird,
.boxFourth {
  position: relative;
  width: calc(670.5px - 4px);
  height: 400px;
  object-fit: contain;
  row-gap: 2rem;
  &::after {
    content: "";
    position: absolute;
    bottom: 0;
    width: 100%;
    height: 100%;
    background: linear-gradient(180deg, rgba(0, 0, 0, 0) 0%, #000000a9 100%);
    border-radius: inherit;
  }
}
@media (width <= 1100px) {
  .boxAdvantage {
    .boxFirst,
    .boxSecond,
    .boxThird,
    .boxFourth {
      padding-inline: 16px;
    }
  }
}

@media (max-width: 1024px) {
  .boxAdvantage {
    padding: 0 !important;
    .boxFirst,
    .boxSecond,
    .boxThird,
    .boxFourth {
      width: 48vw;
      height: 300px;
      background-size: fill;
    }

    .boxThird {
      border-bottom-left-radius: 12px;
    }
  }
}
@media (max-width: 900px) {
  .boxAdvantage {
    padding: 0 !important;
    .boxFirst,
    .boxSecond,
    .boxThird,
    .boxFourth {
      width: 47vw;
      height: 300px;
      background-size: fill;
    }

    .boxThird {
      border-bottom-left-radius: 12px;
    }
  }
}
@media (max-width: 768px) {
  .boxAdvantage {
    row-gap: 2px;
    justify-content: flex-start;
    .boxFirst,
    .boxSecond,
    .boxThird,
    .boxFourth {
      row-gap: 12px;
      justify-content: flex-end;
      padding-bottom: 24px;
      background-position: center;
      background-size: fill;
      background-position: top;
      width: 100%;
      height: 259px;
      flex-shrink: 0;
      &::after {
        content: "";
        position: absolute;
        bottom: 0;
        width: 100%;
        height: 100%;
        // background: linear-gradient(180deg, rgba(0, 0, 0, 0) 0%, #000000);
        background: linear-gradient(180deg, rgba(0, 0, 0, 0) 0%, #000 110%);
        border-radius: inherit;
      }
    }
    .boxThird {
      border-bottom-left-radius: 0;
    }
    .boxSecond {
      border-top-right-radius: 0;
    }
    .boxFourth {
      border-bottom-left-radius: 12px;
    }
    .boxFirst {
      border-top-right-radius: 12px;
    }
    .titleAdvantage {
      font-size: 24px;
      font-weight: 700;
      line-height: 28px;
    }
  }
}
