@import "../../../themes/color";

.wrapperFooter {
  padding: 48px 0;
  display: flex;
  justify-content: center;
  width: 100%;
  background-color: $NEUTRAL-100;
  gap: 2rem;
  .information,
  .mailBox {
    padding: 1.5rem 2rem;
    border: 1px solid $NEUTRAL-90;
    border-radius: 0.6rem;
  }
  .information {
    max-width: 1089px;
    width: 80%;
    display: flex;
    gap: 2rem;
    justify-content: space-between;
    color: $NEUTRAL-30;

    .address {
      display: flex;
      flex-direction: column;
      row-gap: 2rem;
      > img {
        width: 239px;
        height: 24px;
        flex-shrink: 0;
      }
      > div {
        display: flex;
        flex-direction: column;
        row-gap: 8px;
        span {
          font-size: 14px;
        }
        p {
          max-width: 239px;
          font-size: 12px;
          font-weight: 500;
          text-decoration-line: underline;
          cursor: pointer;
        }
      }
    }
    .flexContain {
      display: flex;
      gap: 35px;
    }
    .navigation,
    .informationApp {
      text-align: end;
      display: flex;
      flex-direction: column;
      color: $NEUTRAL-30;
      row-gap: 1rem;
      > h2 {
        font-size: 16px;
        font-weight: 700;
        margin-top: 5px;
        margin-bottom: calc(46px - 1rem);
      }
    }
    .navigation {
      .listMenu {
        display: flex;
        flex-direction: column;
        row-gap: 12px;
        text-align: end;
        > span {
          font-size: 14px;
          cursor: pointer;
        }
      }
    }
    .informationApp {
      .listMenu {
        display: flex;
        flex-direction: column;
        row-gap: 12px;
        > span {
          font-size: 14px;
          cursor: pointer;
          text-align: end;
        }
      }
    }
  }
  .mailBox {
    display: flex;
    flex-direction: column;
    text-align: start;
    max-width: 490px;
    row-gap: 12px;

    > h3 {
      color: $NEUTRAL-10;
      font-size: 24px;
      font-weight: 700;
      line-height: 28px;
    }
    .text {
      display: flex;
      flex-direction: column;
      color: $NEUTRAL-30;
      div {
        color: $NEUTRAL-30;
        font-size: 14px;
        font-style: normal;
        font-weight: 500;
        line-height: 20px;
      }
      span {
        color: $NEUTRAL-70;
        font-size: 12px;
        font-style: normal;
        font-weight: 400;
        line-height: 14px;
      }
    }
    .buttonSend {
      max-width: 426px;
      height: 40px;
      padding: 6px;
      padding-left: 1rem;
      border-radius: 50px;
      display: flex;
      gap: 6px;
      align-items: center;
      background-color: $NEUTRAL-10;
      justify-content: space-between;
      .input {
        border: none;
        outline: none;
        height: 30px;
        font-size: 1rem;
        width: 100%;
        &::placeholder {
          font-size: 1rem;
        }
      }
      .iconNext {
        padding: 4px;
        border-radius: 50%;
        background-color: $NEUTRAL-100;
        display: flex;
        align-items: center;
        cursor: pointer;
        .icon {
          width: 20px;
          height: 20px;
          color: $NEUTRAL-10;
          flex-shrink: 0;
        }
      }
    }
  }
  .socialMedia {
    display: flex;
    gap: 8px;
    .icon {
      color: $NEUTRAL-10;
      width: 32px;
      height: 32px;
      flex-shrink: 0;
      cursor: pointer;
    }
  }
}
@media (max-width: 768px) {
  .flexContain {
    display: flex;
    gap: 24px;
  }
  .wrapperFooter {
    display: flex;
    flex-direction: column;
    padding-inline: 1rem;
    .information,
    .mailBox {
      padding: 1rem;
      width: 100%;
    }
    .information {
      flex-direction: column;
      row-gap: 32px;
      .navigation {
        row-gap: 12px;
      }
      .informationApp {
        align-items: flex-start;
        row-gap: 12px;

        > h2 {
          font-size: 16px;
          text-align: left;
          margin-bottom: 12px;
        }
        .listMenu {
          align-items: flex-start;
          row-gap: 12px !important;
          gap: 0;
          span {
            text-align: start !important;
            width: fit-content !important;
            max-width: 100% !important;
          }
        }
      }
      gap: 32px;
      .address {
        > img {
          width: 239px;
          height: 24px;
        }
        > div {
          > span {
            font-size: 12px;
          }
          p {
            font-size: 12px;
            max-width: 239px;
          }
        }
      }
    }
    .information {
      .navigation,
      .informationApp {
        text-align: end;
        display: flex;
        flex-direction: column;
        color: $NEUTRAL-30;
        > h2 {
          font-size: 16px;
          font-weight: 700;
          margin: 0;
          margin-bottom: 12px;
        }
        .listMenu {
          text-align: left;
          span {
            font-size: 12px;
            max-width: 100px;
          }
        }
      }
    }
    .mailBox {
      > h3 {
        font-size: 1rem;
      }
      .text {
        > div {
          font-size: 12px;
          line-height: 1rem;
        }
        .span {
          font-size: 10px;
        }
      }
      .buttonSend {
        height: 32px;
        .input {
          height: 20px;
          font-size: 14px;
          &::placeholder {
            font-size: 14px;
          }
        }
        .iconNext {
          padding: 2px;
          .icon {
            width: 20px;
            height: 20px;
          }
        }
      }
    }

    .socialMedia {
      display: flex;
      width: 100%;
      justify-content: flex-start;
      gap: 8px;
      .icon {
        width: 24px;
        height: 24px;
      }
    }
  }
  .wrapperFooter[mobile-list-products="true"] {
    padding-bottom: 6rem;
  }
}
