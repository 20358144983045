// @use "themes/color";

// .filterPagination {
//   width: 100%;
//   gap: 8px;
// }

// .filterPageBullet {
//   height: 36px;
//   min-width: 36px;
//   border-radius: 4px;
//   border: 1px solid color.$NEUTRAL-40;
//   background-color: color.$NEUTRAL-10;
//   user-select: none;

//   &.active {
//     border: 0;
//     background-color: color.$PRIMARY;
//     span {
//       color: color.$NEUTRAL-10;
//     }
//   }
// }

// .pageEllipsis {
//   width: 20px;
//   user-select: none;
//   span {
//     transform: translateY(-5px);
//   }
// }

// .none {
//   display: none;
// }
@use "themes/color";

.filterPagination {
  width: 100%;
  display: flex;
  justify-content: center;
  gap: 8px;
}

.filterPageBullet {
  height: 36px;
  min-width: 36px;
  border-radius: 4px;
  border: 1px solid color.$NEUTRAL-40;
  background-color: color.$NEUTRAL-10;
  user-select: none;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  &.active {
    border: 0;
    background-color: color.$PRIMARY;

    span {
      color: color.$NEUTRAL-10;
    }
  }
}

.pageEllipsis {
  width: 20px;
  user-select: none;
  display: flex;
  align-items: center;
  justify-content: center;

  span {
    transform: translateY(-5px);
  }
}

.none {
  display: none;
}
