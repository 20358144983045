@import "../../../../themes/color";
.wrapperCardProducts {
  display: flex;
  // flex-wrap: wrap;
  overflow-x: hidden;
  gap: 1rem;
  max-width: calc(1280px + 4rem);
  width: 100%;
  position: relative;
  justify-self: center;
  padding: 0;
  margin: 0;
  .card {
    padding: 1rem;
    border-radius: 16px;
    row-gap: 10px;
    display: flex;
    flex-direction: column;
    position: relative;
    background: $NEUTRAL-20;
    // overflow: scroll;
    max-width: 341px;
    min-width: calc(341px - 1.1rem);
    width: 100%;
    .otr {
      font-size: 10px;
      line-height: 12px;
      color: $NEUTRAL-80;
    }
    .header {
      > div {
        .title {
          color: $NEUTRAL-100;
          font-weight: 700;
        }
        span:nth-child(2) {
          color: $NEUTRAL-50;
          font-size: 14px;
        }
      }
      .discount {
        position: absolute;
        top: 10px;
        right: 10px;
        background-color: $DANGER;
        display: flex;
        justify-content: center;
        align-items: center;
        border-radius: 50%;
        flex-shrink: 0;
        width: 50px;
        height: 50px;
        > span {
          color: $NEUTRAL-10;
          font-size: 14px;
          font-weight: 700;
          position: relative;
          left: 0;
        }
      }
    }
    .image {
      img {
        width: 216px;
        height: 186px;
      }
    }
    .offers {
      gap: 10px;
      .pricing {
        span:nth-child(1) {
          color: $DANGER;
          font-size: 20px;
          font-weight: 700;
          line-height: 24px;
        }
        span:nth-child(2) {
          color: $NEUTRAL-50;
          font-size: 14px;
          font-weight: 500;
          line-height: 20px;
          text-decoration-line: line-through;
        }
      }
      .colors {
        display: flex;
        gap: 2px;
        .chooseColor {
          display: flex;
          .colorBullet {
            width: 24px;
            height: 22px;
            transform: skewX(-30deg);
            perspective: 20px;
            border-top-left-radius: 3px;
            border-top-right-radius: 8px;
            border-bottom-left-radius: 8px;
            border-bottom-right-radius: 3px;
          }
        }
        > span {
          margin-left: 4px;
          font-weight: 700;
          color: $PRIMARY;
        }
      }
    }
    .button {
      padding: 8px 12px;
      border-radius: 8px;
      outline: 1px solid $NEUTRAL-40;
      border: 1px solid transparent;
      background-color: $NEUTRAL-10;
      display: flex;
      justify-content: center;
      cursor: pointer;
      > span {
        color: $NEUTRAL-100;
        text-align: center;
        font-size: 14px;
        font-weight: 700;
        line-height: 20px;
      }
      &:hover {
        border: 1px solid $NEUTRAL-40;
      }
    }
    .wrappPricing {
      display: flex;
      flex-direction: column;
      row-gap: 4px;
    }
  }
}
.promoCardBody {
  display: flex;
  flex-direction: column;
  gap: 2rem;
  align-items: center;
  width: 100%;
  align-self: center;
  .buttonBoxCard {
    max-width: calc(1280px + 4rem);
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
    @media (max-width: 768px) {
      justify-content: center;

      .between {
        display: none !important;
      }
    }
    .between {
      position: absolute;
      right: 0;
      display: flex;
      gap: 1rem;
      .wrapperBtn {
        width: 50px;
        height: 50px;
        flex-shrink: 0;
        display: flex;
        align-items: center;
        justify-content: center;
        border-radius: 50%;
        background-color: rgba(33, 33, 33, 0.5);
        cursor: pointer;
        .icon {
          color: $NEUTRAL-10;
          width: 24px;
          height: 24px;
          flex-shrink: 0;
        }
      }
      .wrapperBtn[is-done="true"] {
        background-color: rgba(33, 33, 33, 0.1);
        cursor: not-allowed;
      }
      .wrapperBtn:nth-child(1) {
        .icon:nth-child(1) {
          transform: rotate(180deg);
        }
      }
    }

    .buttonSection {
      cursor: pointer;
      display: flex;
      padding: 12px 16px;
      justify-content: center;
      align-items: center;
      border-radius: 8px;
      border: 1px solid transparent;
      outline: 1px solid $NEUTRAL-40;
      background-color: $NEUTRAL-10;
      color: $NEUTRAL-100;
      text-align: center;
      font-weight: 700;
      text-decoration: none;
      &:hover {
        border: 1px solid $NEUTRAL-40;
      }
    }
  }
  .titlePromoCard {
    display: flex;
    align-items: center;
    justify-content: center;
    .title {
      color: $NEUTRAL-100;
      text-align: center;
      font-size: 36px;
      font-weight: 700;
      line-height: 36px;
    }
  }
}
@media (max-width: 1024px) {
  .promoCardBody {
    margin-top: 64px;
    // padding-top: 64px;
  }
}
@media (max-width: 768px) {
  .wrapperCardProducts {
    -webkit-overflow-scrolling: touch;
    display: flex;
    overflow-x: scroll;
    scroll-snap-type: x mandatory;
    scroll-padding-left: 16px;
  }

  .card {
    scroll-snap-align: start;
  }

  .promoCardBody {
    margin-top: 0;
    row-gap: 0;
    .titlePromoCard {
      width: 100%;
      display: flex;
      justify-content: space-between;
      .title {
        font-size: 24px;
        font-weight: 700;
      }
      .buttonSection {
        padding: 4px 12px;
        border: 1px solid $NEUTRAL-40;
        border-radius: 8px;
        font-weight: 700;
        &:active {
          background-color: $NEUTRAL-20;
        }
      }
    }
  }
}
