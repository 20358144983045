// @import "../../../theme/variables.scss";

.background {
  height: 100vh;
  width: 100vw;
  background-color: #00000080;
  z-index: 999;
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  display: flex;
  justify-content: center;
  align-items: center;

  .modalContainer {
    background: #fff;
    /* Navbar Blur */
    backdrop-filter: blur(8px);
    width: 40%;
    border-radius: 16px;
    // border: 1px solid var(--neutral-100, #212121);
    border: 1px solid var(--neutral-50, #c7c7c7);
    max-height: 80%;
    overflow-y: scroll;
  }
}

@media screen and (max-width: 1024px) {
  .background {
    .modalContainer {
      width: 50%;
    }
  }
}

@media screen and (max-width: 768px) {
  .background {
    &.moveToEnd {
      justify-content: flex-end;
      align-items: flex-end;

      .modalContainer {
        width: 100% !important;
        border-radius: 16px 16px 0 0;
      }
    }

    &.reduceWidth {
      .modalContainer {
        width: 90% !important;
      }
    }
  }
}
