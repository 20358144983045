@use "themes/color";

.filterSearch {
  width: 100%;
  position: relative;
}

.searchInput {
  width: 100%;
  height: 40px;
  border-radius: 50px;
  border: 2px solid color.$NEUTRAL-70;
  padding-inline: 18px 38px;
  outline: none;
  transition: 0.2s ease-out;
  font-size: 1rem;
  &:focus {
    border: 2px solid color.$PRIMARY;
    box-shadow: 0 5px 5px rgba(107, 106, 106, 0.375);
  }
  &::placeholder {
    color: color.$NEUTRAL-70;
  }
}

.searchBtn {
  background-color: color.$NEUTRAL-100;
  width: 30px;
  aspect-ratio: 1;
  border-radius: 50%;
  position: absolute;
  right: 5px;
  border: 0;
}
