@import "themes/color";
.rangeContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  margin-top: 1rem;
}

.sliderContainer {
  position: relative;
  display: flex;
  flex-direction: column;
  width: 100%;
  row-gap: -10px;
}

.slider {
  -webkit-appearance: none;
  appearance: none;
  width: 100%;
  height: 8px;
  border-radius: 5px;
  background: $PRIMARY;
  outline: none;
  transition: opacity 0.2s;
  pointer-events: none;
  &:nth-child(2) {
    position: relative;
    top: 10px;
    background: transparent !important;
  }
}

.sliderMin::-webkit-slider-thumb,
.sliderMax::-webkit-slider-thumb {
  -webkit-appearance: none;
  appearance: none;
  width: 28px;
  height: 28px;
  border-radius: 50%;
  background: $NEUTRAL-10;
  box-shadow: 0 0 6px rgba(0, 0, 0, 0.402);
  pointer-events: auto;
}
.sliderMax::-webkit-slider-thumb {
  position: relative;
  top: -1.1rem;
}
.sliderMin::-moz-range-thumb,
.sliderMax::-moz-range-thumb {
  pointer-events: auto;
  -webkit-appearance: none;
  appearance: none;
  width: 28px;
  height: 28px;
  border-radius: 50%;
  background: $NEUTRAL-10;
  box-shadow: 0 0 6px rgba(0, 0, 0, 0.402);
  cursor: pointer;
}
