@use "themes/color";
@import "themes/global";
.container {
  width: 100vw;
  min-height: 100vh;
  background-color: color.$NEUTRAL-20;
  .wrapModalSimulation {
    padding: 1rem;
    border-radius: 8px;
    background-color: color.$NEUTRAL-10;
    @include flexCol(16px);
    .header {
      @include flexBetween;
      align-items: center;
      > div {
        @include flex(8px);
        align-items: center;
        > span {
          color: color.$NEUTRAL-100;
          font-family: Madson;
          font-size: 20px;
          font-weight: 700;
        }
        > .wrapLogo {
          @include flexCenter();
          width: 36px;
          aspect-ratio: 1;
          padding: 5px 11px 4px 11px;
          border: 1px solid color.$PRIMARY-BORDER;
          border-radius: 50%;
          > img[alt="logo-motor"] {
            width: 14px;
            height: 27px;
            flex-shrink: 0;
          }
        }
      }
      .closeIcon {
        cursor: pointer;
        flex-shrink: 0;
      }
    }
    .contentModal {
      @include flexCol(12px);
      .childHead {
        h5 {
          font-size: 1rem;
          font-weight: 700;
        }
      }
      .wrapperlist {
        @include flex(0);
        font-size: 14px;
        > .label {
          font-weight: 700;
          > ul {
            @include flexCol(8px);
            width: 151px;
            li {
              list-style-type: none;
            }
          }
        }
        > .value {
          ul {
            @include flexCol(8px);

            li {
              list-style-type: none;
            }
          }
        }
      }
    }
    .buttonModal {
      cursor: pointer;
      padding: 8px 12px;
      border-radius: 8px;
      background-color: color.$PRIMARY;
      color: color.$NEUTRAL-10;
      @include flexCenter();
      &:hover {
        background-color: color.$PRIMARY-HOVER;
      }
      > .iconWhatsApp {
        width: 20px;
        height: 20px;
        flex-shrink: 0;
      }
    }
  }
}

.aligner {
  width: 100%;
  max-width: 1340px;
  // padding-inline: 50px;
  transform: translateY(-40px);

  @media (max-width: 576px) {
    padding-inline: 16px;
  }
}
// @media (max-width: 768px) {
//   .aligner {
//     max-width: 358px;
//     background-color: salmon;
//   }
// }
